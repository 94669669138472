export const dateBrToUs = (date: string): Date => {
  const _date = date.split("/");
  const _normalizeDate = `${_date[1]}/${_date[0]}/${_date[2]}`;
  return new Date(_normalizeDate);
};

type Hours = {
  hours: number;
  min: number;
  sec?: number;
};

export const getToday = (time: Hours) => {
  const { hours, min, sec } = time;

  let d = new Date();
  d.setHours(hours, min, sec || 0);
  return d;
};

export const getYesterday = (time: Hours, dateOnly?: boolean) => {
  const { hours, min, sec } = time;

  let d = new Date();
  d.setDate(d.getDate() - 1);
  d.setHours(hours, min, sec || 0);
  return dateOnly ? new Date(d.toDateString()) : d;
};
