import { useState } from "react";
import { UserMenu } from "../../@types/DataTypes";
import { ReactComponent as Arrow } from "../../assets/icons/arrowMenu.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { useOutsideClick } from "../../utils/use";

import "./OUser.scss";

type Props = {
  letter?: string;
  name: string;
  menu?: UserMenu[];
  onLogoutClick?: () => void;
};

const OUser = (props: Props) => {
  const { letter, name, menu, onLogoutClick } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const handleClickOutside = () => {
    setMenuOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  //TODO :: user outside

  return (
    <div ref={ref} className="OUser" onClick={() => menu && setMenuOpen((oldValue) => !oldValue)}>
      <div className={`OUser__Container ${menu ? "OUser--interaction" : ""}`}>
        <div className="OUser__Profile">{letter ? letter?.toUpperCase() : <User className="OUser__Doll" />}</div>
        <div className="OUser__Name">
          <span>{name}</span>
          {menu && <Arrow className="OUser__Arrow" />}
        </div>
      </div>
      {menu && (
        <div className={`OUser__Menu ${!menuOpen ? "OUser__Menu--hide" : ""}`}>
          <ul>
            {menu.map((item, index) => (
              <li key={index} onClick={() => item.action()}>
                {item.label}
              </li>
            ))}
            <li onClick={() => onLogoutClick && onLogoutClick()}>Sair</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default OUser;
