import { useState } from "react";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeLine } from "../../assets/icons/eyeLine.svg";
import "./OInput.scss";

type Props = {
  label: string;
  type: string;
  name?: string;
  value?: string;
  required?: boolean;
  onChange: (value: string | any, name: string | any) => void;
  autofocus?: boolean;
  error?:boolean;
  step?:any;
  customClass?: string;
};

const OInput = (props: Props) => {
  const { label, type, value, required, onChange, autofocus, error = false, name, step, customClass } = props;

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <div className={`OInput ${customClass ? customClass : ''} ${error ? 'OInput--error' : ''}`}>
      <label className={`OInput__Label ${value && 'OInput__Label--show'}`}>{label}</label>
      <label className="Input">
        { type !== 'textarea' &&
          <input
            placeholder={label}
            type={visible ? "text" : type}
            step={step}
            value={value}
            required={required}
            onChange={(event) => onChange(event.target.value, name)}
            autoFocus={autofocus}
            autoComplete="on"
          />
        }
        { type === 'textarea' && 
            <textarea
              placeholder={label}
              value={value}
              required={required}
              onChange={(event) => onChange(event.target.value, name)}
              autoFocus={autofocus}
              autoComplete="on"
            />
        }
        { type === "password" && (
          <div className="OInput__Icon" onClick={() => setVisible(!visible)}>
            {visible ? <EyeLine /> : <EyeIcon />}
          </div>
        )}
      </label>
    </div>
  );
};

export default OInput;
