import { useState } from "react";
import { ReactSelect } from "../../@types/DataTypes";

import {
  Button,
  OIconButton,
  OInput,
  OLoading,
  OSelect,
} from "../../components";
import { createAbusiveRule, deleteAbusiveRule, updateAbusiveRule } from "../../services/Olos/OlosAPI";

import "./AddEdit.scss";
import { convertBoolToString, convertStringToBoolean } from "../../utils/string";

type Props = {
  rule: any,
  editMode: true | false,
  onUpdate: ()=> void,
  onRequestClose: ()=> void,
}

export const AddEdit = (props:Props) => {
  const { rule, editMode, onUpdate, onRequestClose } = props;

  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ruleDeleted, setRuleDeleted] = useState(false);
  const [ruleDeletedError, setRuleDeletedError] = useState(false);
  const [ruleUpdated, setRuleUpdated] = useState(false);
  const [ruleUpdatedError, setRuleUpdatedError] = useState(false);
  const [ruleCreated, setRuleCreated] = useState(false);
  const [ruleCreatedError, setRuleCreatedError] = useState(false);
  const [ruleCreatedErrorResponse, setRuleCreatedErrorResponse] = useState<any>();
  const [ruleUpdatedErrorResponse, setRuleUpdatedErrorResponse] = useState<any>();
  const [ruleDeletedErrorResponse, setRuleDeletedErrorResponse] = useState<any>();

  const signalingTypeOptions = [
    {
      label: "Default",
      value: "default"
    },
    {
      label: "SIP",
      value: "sip"
    },
    {
      label: "ISDN",
      value: "isdn"
    },
    {
      label: "R2",
      value: "r2"
    }
  ];

  const alertFlagOptions = [
    {
      label: "Sim",
      value: "true"
    },
    {
      label: "Não",
      value: "false"
    }
  ];

  const [formData, setFormData] = useState<any>({
    signaling: editMode ? rule.signaling : '',
    is_default: editMode ? rule.is_default : '',
    pstn: editMode ? rule.pstn : '',
    codes: editMode ? parseCodes(rule.codes) : '',
  });

  function getOptionByValue(value: any, options:any) {
    let selectedValue = value;

    const option = options.find((option: any)=> {
      return option.value === selectedValue;
    })

    return option;
  }

  function parseCodes(codes:string) {
    let parsedCodes:Array<any> = [];

    try {
      parsedCodes = JSON.parse(codes);

      if (typeof(parsedCodes) === 'string') {
        parsedCodes = JSON.parse(parsedCodes);
      }
    } catch (error) {
      console.log('parseCodes error', error);
    }

    console.log({parsedCodes});

    return parsedCodes.join(',');
  }

  const [selectedSignaling, setSelectedSignaling] = useState<ReactSelect>(editMode ? getOptionByValue(rule.signaling, signalingTypeOptions) : undefined);
  const [selectedIsDefault, setSelectedIsDefault] = useState<ReactSelect>(editMode ? getOptionByValue(convertBoolToString(rule.is_default), alertFlagOptions) : undefined);

  function changeFormData(value: any, name: any) {
    const currentData = {...formData};

    currentData[name] = value;

    setFormData(currentData);
  }

  function createEdit() {
    const data = {...formData};

    data.is_default = convertStringToBoolean(data.is_default);
    data.codes = `[${data.codes}]`;

    console.log('data: ', data);

    setLoading(true);

    if (editMode) {
      data.id = rule.id;

      updateAbusiveRule(data).then((response)=> {
        console.log('response update rule: ', response);
        if (response && response.status === 204) {
          onUpdate();
          setRuleUpdated(true);
        } else {
          setRuleUpdatedError(true);
          setRuleUpdated(false);
        }
      }).catch((error)=> {
        setRuleUpdatedErrorResponse(error.response.data);
        setRuleUpdatedError(true);
        setRuleUpdated(false);
      }).finally(()=>{
        setLoading(false);
      });
    } else {
      createAbusiveRule(data).then((response)=> {
        if (response && response.status === 204) {
          setRuleCreated(true);
          onUpdate();
        } else {
          setRuleCreatedError(true);
          setRuleCreated(false);
        }
      }).catch((error)=> {
        setRuleCreatedErrorResponse(error.response.data);
        setRuleCreatedError(true);
      }).finally(()=>{
        setLoading(false);
      });
    }
  }

  function deleteRule() {
    setLoading(true);

    deleteAbusiveRule(rule.id).then((response)=> {
      if (response && response.status === 204) {
        setRuleDeleted(true);
        onUpdate();
      } else {
        setRuleDeleted(false);
        setRuleDeletedError(true);
      }
    }).catch((error)=> {
      setRuleDeletedError(true);
      setRuleDeletedErrorResponse(error.response.data);
    }).finally(()=>{
      setLoading(false);
    });
  }

  function parseRequestError(requestError: {error: Array<object> | string}) {
    console.log('parseRequestError', requestError);
    const errorData = requestError?.error;

    if (Array.isArray(errorData)) {
      return errorData.map((err: any)=> {
        return <p>{JSON.stringify(err.constraints)}</p>
      });
    } else {
      return <p>{errorData}</p>
    }
  }

  return (
    <section className="AddEdit">
      <section className="AddEdit__Main">
        { loading && <OLoading float /> }

        { showDeleteConfirmation && 
          <div className="AddEdit__StatusContainer">
            {!ruleDeleted && <p>Tem certeza que deseja excluir essa regra?</p>}

            { ruleDeleted && <p>Regra excluída com sucesso!</p>}

            { ruleDeleted && 
              <div className="AddEdit__StatusContainer__Buttons">
                <Button label="OK" size="Small" onClick={onRequestClose} />
              </div>
            }
            
            { !ruleDeleted && 
              <div className="AddEdit__StatusContainer__Buttons">
                <Button label="Não" theme="Danger" size="Small" onClick={()=> {
                  setShowDeleteConfirmation(false);
                }} />
                <Button label="Sim" size="Small" onClick={deleteRule} />
              </div>
            }
          </div>
        }

        { (ruleUpdated || ruleCreated) &&
          <div className="AddEdit__StatusContainer">
            <p>{ruleUpdated ? "Regra atualizada com sucesso!" : "Regra criada com sucesso!"}</p>
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={onRequestClose} />
            </div>
          </div>
        }

        { ruleUpdatedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao atualizar a regra!</p>

            { parseRequestError(ruleUpdatedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleUpdatedError(false)} />
            </div>
          </div>
        }

        { ruleCreatedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao criar a regra!</p>

            { parseRequestError(ruleCreatedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleCreatedError(false)} />
            </div>
          </div>
        }

        { ruleDeletedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao deletar a regra!</p>

            { parseRequestError(ruleDeletedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleDeletedError(false)} />
            </div>
          </div>
        }

        <h1>{editMode ? 'Editar regra' : 'Nova regra'}</h1>
        
        <div className="AddEdit__ContentContainer">
          <div className="AddEdit__FormContainer">
            <OSelect
              label="Sinalização"
              name="signaling"
              options={signalingTypeOptions}
              value={selectedSignaling}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedSignaling(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OSelect
              label="Regra padrão"
              name="is_default"
              options={alertFlagOptions}
              value={selectedIsDefault}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedIsDefault(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OInput
              name="pstn"
              label="Operadora"
              type="text"
              value={formData.pstn}
              required
              onChange={changeFormData}
            />

            <OInput
              name="codes"
              label="Códigos"
              type="textarea"
              customClass="OInput__FullWidth Codes"
              value={formData.codes}
              required
              onChange={changeFormData}
            />
          </div>

          <div className="AddEdit__Footer">
            {editMode &&
              <OIconButton label="Excluir regra" icon="trash" danger={true} onClick={()=> {
                setShowDeleteConfirmation(true);
              }}/>
            }

            <Button label={editMode ? 'Salvar' : 'Enviar'} size="Small" onClick={createEdit} />
          </div>
        </div>
      </section>
    </section>
  );
};