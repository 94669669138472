import "./button.scss";

type Props = {
  type?: "button" | "submit" | "reset" | undefined;
  theme?: "Orange" | "Border" | "Danger";
  size?: "Big" | "Small";
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  name?: string;
};

const Button = (props: Props) => {
  const { type, onClick, label, disabled, theme, name, size } = props;

  return (
    <button
      className={`Button Button--${theme || "Orange"} Button--${size || "Big"} ${name || ""}`}
      type={type || "button"}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
