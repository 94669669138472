import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Navigate, useLocation } from "react-router-dom";
import {
  Button,
  OHeader,
  OLoading,
  OTableGrid,
  OIconButton,
  OModal,
} from "../../components";
import { getRules } from "../../services/Olos/OlosAPI";

import "./AlertsManager.scss";
import { AddEdit } from "./AddEdit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { checkUserRole } from "../../utils/auth";

export const AlertsManager = () => {
  const userRoles = useSelector((state: RootState) => state.config.userInfo?.roles);

  const location = useLocation();
  const [pageLiveData, setPageLiveData] = useState<any[]>([]);
  const [pageError, setPageError] = useState("");
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentRule, setCurrentRule] = useState<any>();
  const [editMode, setEditMode] = useState(false);
  
  const [responseErrorData, setResponseErrorData] = useState<any>();

  const tableExtractDataColumns = [
    {
      label: "Tipo",
      id: 'alertType',
    },
    {
      label: "Subtipo",
      id: 'alertSubType',
    },
    {
      label: "Valor",
      id: 'alertValue',
    },
    {
      label: "Ativa",
      id: 'active',
    },
    {
      label: "Período",
      id: 'fullPeriodBy',
    },
    {
      label: "Máx. Abusivas",
      id: 'maxAbusiveCalls',
    },
    {
      label: "Máx. Abusivas (%)",
      id: 'maxAbusiveCallsPercent',
    },
    {
      label: "Total mín. (Cham.)",
      id: 'minimumTotalCalls',
    },
    // {
    //   label: "Plataforma (Id)",
    //   id: 'platformId',
    // },
    // {
    //   label: "Fator de Segurança",
    //   id: 'safetyFactor',
    // },
    {
      label: "Lim. Desbloq. Max. Abusivas",
      id: 'unblockMaxAbusiveCalls',
    },
    {
      label: "Lim. Desbloq. Max. Abusivas (%)",
      id: 'unblockMaxAbusiveCallsPercent',
    },
    {
      label: "Desbloq. Mudança de Período ",
      id: 'unblockWhenPeriodChange',
    },
    {
      label: "",
      id: 'edit',
      render: (id:any, item:any)=> {
        return (
          <OIconButton icon="edit" onClick={()=> editItem(item)} />
          // <button onClick={()=> editItem(item)}>Editar</button>
        )
      }
    },
    // {
    //   label: "",
    //   id: 'delete',
    //   render: (id:any, item:any)=> {
    //     return (
    //       <button onClick={()=> console.log('->>>> ITEM', item)}>Excluir</button>
    //     )
    //   }
    // }
  ]

  function editItem(item:any) {
    setCurrentRule(item);
    setShowEditModal(true);
    setEditMode(true);
  }

  const getAlertRules = async () => {
    setLoadingAlerts(true);

    const alertsData = await getRules().catch((error)=> {
      return error;
    });

    // console.log('alertsData', alertsData);

    if ((alertsData as AxiosResponse).status === 200) {
      // console.log('all alerts', alertsData);

      setResponseErrorData(null);
      setPageLiveData(
        alertsData.data.rules.map((item:any) => {
          return { ...item, openDetails: false };
        })
      );
    } else {
      setResponseErrorData(alertsData.response.data);
      setPageError("");
      setPageLiveData([]);
    }

    setLoadingAlerts(false);
  }

  useEffect(() => {
    getAlertRules();

  }, []);

  if (!checkUserRole("supervisor", userRoles)) {
    return <Navigate to="/" />
  }

  return (
    <section className="AlertsManager">
      { responseErrorData && 
        <OModal name="ErrorModal" removeInfo onClose={()=> setResponseErrorData(null)}>
          <div className="ErrorModal__Content">
            <p>Ocorreu um erro ao recuperar os dados!</p>
            <p>{responseErrorData.error}</p>

            <Button size="Small" label="OK" onClick={() => {
              setResponseErrorData(null);
            }} />
          </div>
        </OModal>
      }

      {showEditModal && 
        <OModal name="AddEditModal" removeInfo onClose={()=> setShowEditModal(false)}>
          <AddEdit
            rule={currentRule}
            editMode={editMode}
            onUpdate={getAlertRules}
            onRequestClose={()=> {setShowEditModal(false)}}
          />
        </OModal>
      }
      <OHeader currentLink={location.pathname} />
      
      <section className="AlertsManager__Main">
        { loadingAlerts && <OLoading float /> }

        <main className="AlertsManager__Content">
          <div className="AlertsManager__Content__container">

            <div className="AlertsManager__HeaderContent">
              <OIconButton label="Adicionar nova regra" icon="plus" onClick={()=> {
                setEditMode(false);
                setShowEditModal(true);
              }}/>
            </div>

            <h1>Regras de alertas</h1>

            { !pageLiveData.length &&
              <div className="AlertsManager__Loading">
                <div className="AlertsManager__Error">
                  <p className={pageError ? "AlertsManager__Error" : ""}> {pageError || "Nenhuma regra encontrada."}</p>
                  <br/>
                  <Button size="Small" label="Tentar novamente" onClick={() => {
                      getAlertRules();
                  }} />
                </div>
              </div>
            }
            
            { !!pageLiveData.length && 
              <div className="AlertsManager__TableData">
                <div className="AlertsManager__TableContent">
                  <OTableGrid
                    columns={tableExtractDataColumns}
                    data={pageLiveData}
                    useGridTemplate={false}
                    // onLineClick={(line)=> {console.log(line)}}
                  />
                </div>
              </div>
            }
          </div>
        </main>
      </section>
    </section>
  );
};