/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from "react";
import { format, isBefore } from "date-fns";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

import {
  Button,
  OHeader,
  OLoading,
  OSelect,
  OInputForm,
  OModal,
  OCheckbox,
  OTabs,
  OButtonIcon,
  OPagination,
  OToogle,
} from "../../components";

import {
  Anis,
  AnisLive,
  PageCategory,
  Pagination,
  ParamsAmount,
} from "../../@types/OlosTypes";
import { dateBrToUs } from "../../utils/date";
import { ReactSelect, UserMenu } from "../../@types/DataTypes";
import { useDispatch, useSelector } from "react-redux";
import { setNewSearchStatus, setSearchDate } from "../../store/slices/slices";
import { useInterval } from "../../utils/useInterval";
import PageContent from "../Data.json";

import { useLocation, useNavigate } from "react-router-dom";
import { GeralConfig } from "../GeralConfig/GeralConfig";

import "./Home.scss";
import "./Config.scss";
import {
  getAllFilters,
  getDataByColumnCategory,
  getFilterOptionsByContract,
  getFilterOptionsByPlatform,
  getFilterOptionsByPSTN,
  getFilterOptionsByRoute,
  getTotalObjectsByColumn,
} from "../../services/Olos/OlosAPI";
import OGrid from "../../components/OAniGrid/OGrid";
import { AxiosResponse } from "axios";
import { RootState } from "../../store";
import { AlertsSidebar } from "../../components/AlertsSidebar/AlertsSidebar";
import { S } from "msw/lib/SetupServerApi-70cc71a7";

const categories: PageCategory[] = [
  {
    type: "/",
    columnId: "ani",
    tableTitle: "ANI",
  },
  {
    type: "/operadoras",
    columnId: "pstn",
    tableTitle: "Operadora",
  },
  {
    type: "/contratos",
    columnId: "contract",
    tableTitle: "Contrato",
  },
  {
    type: "/rotas",
    columnId: "route",
    tableTitle: "Rota",
  },
  {
    type: "/campanhas",
    columnId: "campaignid",
    tableTitle: "Campanha",
  },
  {
    type: "/plataformas",
    columnId: "platformid",
    tableTitle: "Plataforma",
  },
];

type ANIFilters = {
  campaignid: [];
  contract: [];
  platformid: [];
  pstn: [];
  route: [];
};

export const Home = () => {
  const {
    startDate: periodStart,
    time,
    pagination,
    canEdit,
  } = (window as any).config;

  const {
    modalfirstDate,

    modalSecondDate,

  } = (window as any).config;

  const location = useLocation();
  const navigate = useNavigate();
  const [currentPageCategory, setCurrentPageCategory] = useState<
    PageCategory | undefined
  >();

  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());
  const startDate = useSelector((state: RootState) => state.config.searchDate);

  const [pageData, setPageData] = useState<Anis[]>([]);
  const [pageLiveData, setPageLiveData] = useState<AnisLive[]>([]);
  const [pageError, setPageError] = useState("");
  const [currentPage, setCurrentPage] = useState<Pagination>({
    page: 0,
    offset: 0,
  });

  const [allAni, setAllAni] = useState<ReactSelect[]>([]);
  const [selectedAni, setSelectedAni] = useState<ReactSelect[]>([]);

  const [lastUpdate, setLastUpdate] = useState<string>("");

  const [loadingPage, setLoadingPage] = useState(false);

  const [monitoring, setMonitoring] = useState(false);
  const [config, setConfig] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  // const [pstnFilterOptions, setPSTNFilterOptions] = useState<ReactSelect[]>([]);
  // const [contractFilterOptions, setContractFilterOptions] = useState<ReactSelect[]>([]);
  // const [routeFilterOptions, setRouteFilterOptions] = useState<ReactSelect[]>([]);
  // const [platformFilterOptions, setPlatformFilterOptions] = useState<ReactSelect[]>([]);

  const [organization, setOrganization] = useState<ReactSelect[] | undefined>(
    []
  );

  const [rote, setRote] = useState<ReactSelect[] | undefined>([]);

  const [tabs, setTabs] = useState(PageContent.configMenu);
  const [tabName, setTabName] = useState("geral");

  const [totalElements, setTotalElements] = useState<number>(0); // Total de elementos da paginação

  const [filters, setFilters] = useState<ANIFilters>({
    campaignid: [],
    contract: [],
    platformid: [],
    pstn: [],
    route: [],
  });

  const [selectedPlatform, setSelectedPlatform] = useState<ReactSelect>();
  const [selectedContract, setSelectedContract] = useState<ReactSelect>();
  const [selectedRoute, setSelectedRoute] = useState<ReactSelect>();
  const [selectedANI, setSelectedANI] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<
    [{ column: string; value: string }] | []
  >([]);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);

  const [responseError, setResponseError] = useState(false);

  const [firstDateModal, setFirstDateModal] = useState(false);
  const [secondDateModal, setSecondDateModal] = useState(false);

  const dispatch = useDispatch();

  const _resetConfig = () => {
    setSelectedAni([]);
    setSelectAll(false);
  };

  const getPagination = async (filters: any = undefined) => {
    let currentPagination = currentPage;
    const currentFilters = filters || selectedFilters;

    if (shouldResetPagination) {
      currentPagination = { page: 0, offset: 0 };
      setCurrentPage(currentPagination);
      setShouldResetPagination(false);
    } else {
      setCurrentPage(currentPagination);
    }

    const currentDate =
      startDate.getFullYear() +
      "-" +
      ("0" + (startDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + startDate.getDate()).slice(-2);
    const totalAnis = await getTotalObjectsByColumn(
      currentDate,
      currentFilters,
      currentPageCategory?.columnId
    );

    if ((totalAnis as AxiosResponse).status === 200) {
      setTotalElements(totalAnis.data.total);
      setResponseError(false);
    } else {
      setLoadingPage(false);
      setPageError("Houve um erro ao recuperar os dados.");
      return setResponseError(true);
    }
  };

  const getANIList = async (
    pagination: Pagination,
    filters: any = undefined
  ) => {
    const currentFilters = filters || selectedFilters;

    setLoadingPage(true);
    dispatch(setNewSearchStatus(true));

    const currentDate =
      startDate.getFullYear() +
      "-" +
      ("0" + (startDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + startDate.getDate()).slice(-2);

    const response = await getDataByColumnCategory(
      currentDate,
      true,
      50,
      currentPage.offset,
      currentFilters,
      currentPageCategory?.columnId
    );

    setSelectedFilters(filters as any);

    // console.log("getANIList response", response);

    const _date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
    setLastUpdate(_date);

    if ((response as AxiosResponse).status === 200) {
      setPageLiveData(
        response.data.map((item) => {
          return { ...item, openDetails: false };
        })
      );
    } else {
      setPageError("");
      setPageLiveData([]);
    }

    setLoadingPage(false);
    dispatch(setNewSearchStatus(false));
  };
  const convertOptionsToObject = (options: []) => {
    return options.map((opt) => {
      return {
        value: opt,
        label: opt,
      };
    });
  };

  const saveAndParseFilters = (filtersData: []) => {
    let currentFilters = { ...filters } as any;

    //console.log("saveAndParseFilters ", currentFilters);

    filtersData.map((filter) => {
      const filterOptions = (filter as any).values;
      const parsedFilters = convertOptionsToObject(filterOptions);
      currentFilters[(filter as any).column] = parsedFilters;
    });

    setFilters(currentFilters);
  };

  useEffect(() => {
    //console.log("filters: ", filters);
  }, [filters]);

  const getFilters = async () => {
    if (responseError) return;

    try {
      const currentDate =
        startDate.getFullYear() +
        "-" +
        ("0" + (startDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + startDate.getDate()).slice(-2);

      setIsLoadingFilters(true);

      // const pstnOptions = await getFilterOptionsByPSTN(currentDate);
      // setPSTNFilterOptions(convertOptionsToObject(pstnOptions.data));

      // const contractOptions = await getFilterOptionsByContract(currentDate);
      // setContractFilterOptions(convertOptionsToObject(contractOptions.data));

      // const routeOptions = await getFilterOptionsByRoute(currentDate);
      // setRouteFilterOptions(convertOptionsToObject(routeOptions.data));

      // const platformOptions = await getFilterOptionsByPlatform(currentDate);
      // setPlatformFilterOptions(convertOptionsToObject(platformOptions.data));

      const allFilters = await getAllFilters(currentDate);

      saveAndParseFilters(allFilters.data);

      setIsLoadingFilters(false);
    } catch (error) {
      setIsLoadingFilters(false);
      //console.log("TODO - Exibir erro ao buscar os filtros");
    }
  };

  const onChangeFilter = (resetAllFilters = false) => {
    setShouldResetPagination(true);

    if (resetAllFilters) {
      setFilters({
        campaignid: [],
        contract: [],
        platformid: [],
        pstn: [],
        route: [],
      });
    }
  };

  useEffect(() => {
    if (currentPageCategory && currentPageCategory.type === location.pathname) {
      getANIList(currentPage);
      if (currentPageCategory?.type === "/") {
        getFilters();
      }
    } else {
      setCurrentPageCategory(
        categories.find((category) => {
          return category.type === location.pathname;
        })
      );
    }
  }, [startDate, currentPageCategory, location, currentPage]);

  useEffect(() => {
    if (monitoring) {
      dispatch(setSearchDate(new Date()));
      return;
    }
  }, [monitoring]);

  useEffect(() => {
    setTabs((old) =>
      old.map((item) => {
        return {
          ...item,
          activie: item.id === tabName,
        };
      })
    );
  }, [tabName]);

  const _getTimeMonitoring = () => {
    const _time = parseInt(time);
    const _localTime = localStorage.getItem("MonitoringTime");

    const _configTime = _time < 10 ? 10 : _time;
    const _storageTime = _localTime ? parseInt(_localTime) : _configTime;

    return _storageTime * 1000;
  };

  useInterval(
    async () => {
      await getANIList(currentPage);

      const _date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
      setLastUpdate(_date);
    },
    monitoring ? _getTimeMonitoring() : null
  );

  const entryDateRules = {
    beforeDate: periodStart ? dateBrToUs(periodStart) : null,
    afterDate: new Date(),
  };

  const headerMenu: UserMenu[] = [
    {
      label: "Console",
      action: () => navigate("/console"),
    },
    // {
    //   label: "Configurações",
    //   action: () => {
    //     setConfig(true);
    //     setMonitoring(false);
    //   },
    // },
  ];

  const _gridSize = canEdit
    ? "min-content 0.6fr .9fr .9fr .9fr"
    : "0.6fr .9fr .9fr .9fr";
  const _gridGap = "10px";

  const _saveGeralConfig = (config: { time: string; pagination: string }) => {
    const _time = parseInt(config.time);
    localStorage.setItem(
      "MonitoringTime",
      _time < 10 ? "10" : _time.toString()
    );

    setConfig(false);
    return _resetConfig();
  };

  const _saveAniConfig = () => {
    // getAmountData();
  };

  const _updateAniList = (data: { ani: AnisLive; status: boolean }) => { };

  const _selectAll = (flag: boolean) => {
    const _anis = [...pageData];
    const _pageData = _anis.map((item) => {
      return {
        ...item,
        openDetails: flag,
      };
    });

    setPageData(_pageData);
    setSelectedAni(
      _pageData
        .filter((item) => item.openDetails)
        .map((item, index) => {
          return {
            label: item.Ani,
            value: index.toString(),
          };
        })
    );
  };

  const _configViews: { [key: string]: ReactElement } = {
    geral: (
      <GeralConfig
        onSave={(pageData) => _saveGeralConfig(pageData)}
        onCancel={() => setConfig(false)}
      />
    ),
    ani: (
      <div></div>
      // <AniConfig
      //   allAni={allAni}
      //   selectedsAni={selectedAni}
      //   onSave={() => _saveAniConfig()}
      //   onCancel={() => {
      //     _resetConfig();
      //     return setConfig(false);
      //   }}
      //   setSelectedAni={(anis) => setSelectedAni(anis)}
      // />
    ),
  };

  const _parseTabs = () => {
    return !canEdit ? tabs.filter((tab) => tab.id !== "ani") : tabs;
  };

  const disableFiltersExceptDatefilter =
    currentPageCategory?.type !== "/" || isLoadingFilters;


  const handleCloseModal = () => {
    setFirstDateModal(false);
    setSecondDateModal(false);
  };

  return (
    <section className="Home">
      <OHeader currentLink={currentPageCategory?.type} userMenu={headerMenu} />

      <section className="Home__Main">
        <aside className="Home__Menu">
          {isLoadingFilters && <OLoading float />}
          <div className="Home__Menu__Content">
            <div className="Home__PageTitle">
              <span className="bold">Filtros</span>
            </div>

            <p className="Home__Title">Data</p>

            <div className="Home__Periodo">
              <div className="DatePicker">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date: Date) => {
                    // setStartDate(date)
                    dispatch(setSearchDate(date));
                    const isBeforeDate = isBefore(new Date(2024, 4, 31, 23, 59, 59), date)
                    if (currentPageCategory?.type === "/") {
                      if (!isBeforeDate) {
                        setFirstDateModal(true);
                      } else {
                        setSecondDateModal(true);
                      }
                    }
                    onChangeFilter(true);
                  }}
                  locale={ptBR}
                  disabled={monitoring}
                />
              </div>

              <div className="Home__Field">
                <OSelect
                  label="Plataformas"
                  placeholder="Selecione uma opção"
                  // options={platformFilterOptions || []}
                  options={filters.platformid || []}
                  value={selectedPlatform}
                  disable={monitoring || disableFiltersExceptDatefilter}
                  error={!organization}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedPlatform(_value);
                    onChangeFilter();
                  }}
                />
              </div>

              <div className="Home__Field">
                <OInputForm
                  label="ANI"
                  value={selectedANI}
                  disable={disableFiltersExceptDatefilter}
                  onChange={(value) => {
                    setSelectedANI(value);
                    onChangeFilter();
                  }}
                />
              </div>
              <div className="Home__Field">
                <OSelect
                  label="Contratos"
                  placeholder="Selecione uma opção"
                  // options={contractFilterOptions}
                  options={filters.contract}
                  value={selectedContract}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedContract(_value);
                    onChangeFilter();
                  }}
                  disable={monitoring || disableFiltersExceptDatefilter}
                />
              </div>

              <div className="Home__Field">
                <OSelect
                  label="Rotas"
                  placeholder="Selecione uma ou mais opções"
                  // options={routeFilterOptions || []}
                  options={filters.route || []}
                  value={selectedRoute}
                  error={!rote}
                  disable={monitoring || disableFiltersExceptDatefilter}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedRoute(_value);
                    onChangeFilter();
                  }}
                />
              </div>

              <Button
                name="Home__Search"
                label="Pesquisar"
                disabled={monitoring}
                onClick={() => {
                  const filters = [];

                  if (selectedANI)
                    filters.push({ column: "ani", value: selectedANI });
                  if (selectedContract)
                    filters.push({
                      column: "contract",
                      value: selectedContract.value,
                    });
                  if (selectedRoute)
                    filters.push({
                      column: "route",
                      value: selectedRoute.value,
                    });
                  if (selectedPlatform)
                    filters.push({
                      column: "platformid",
                      value: selectedPlatform.value,
                    });

                  getANIList(pagination, filters);
                }}
              />
            </div>
          </div>
        </aside>
        {!!pageLiveData.length && (!loadingPage || monitoring) ? (
          <main className="Home__Content">
            <div className="Home__Controller">
              <div className="Home__ControllerContainer">
                {canEdit && (
                  <OButtonIcon
                    label="Editar Total por Ani"
                    icon={<Edit />}
                    disable={
                      !pageData.filter((item) => item.openDetails).length
                    }
                    onClick={() => {
                      setTabName("ani");
                      setConfig(true);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="Home__TableData">
              <div
                className="Home__TableHeader"
                style={{ gridTemplateColumns: _gridSize, gap: _gridGap }}
              >
                {canEdit && (
                  <div>
                    <OCheckbox
                      checked={selectAll}
                      value="all"
                      onChange={(value) => {
                        setSelectAll(value.activie);
                        _selectAll(value.activie);
                      }}
                    />
                  </div>
                )}

                <div>{currentPageCategory?.tableTitle || ""}</div>
                <div>Total de ligações</div>
                <div>Ligações Boas</div>
                <div>Ligações Ruins</div>
              </div>
              <div className="Home__TableContent">
                {pageLiveData.map((item, index) => (
                  <OGrid
                    key={index}
                    data={item}
                    gridArea={_gridSize}
                    gridGap={_gridGap}
                    period={startDate}
                    canEdit={canEdit}
                    // filter={_getFilters()}
                    onLineSelect={(ani) => _updateAniList(ani)}
                  />
                ))}
              </div>

              {!!pagination && (
                <OPagination
                  itemsPerPage={50}
                  items={totalElements}
                  currentPage={currentPage}
                  pageChange={(pagination) => {
                    setCurrentPage(pagination);
                  }}
                />
              )}
            </div>
          </main>
        ) : (
          <div className="Home__Loading">
            {loadingPage ? (
              <OLoading float />
            ) : (
              <div className="Home__Error">
                <p className={pageError ? "Home__Error" : ""}>
                  {" "}
                  {pageError || "Não foram encontrados dados"}
                </p>
                <br />
                <Button
                  name="Home__Search"
                  size="Small"
                  label="Tentar novamente"
                  onClick={() => {
                    setResponseError(false);
                    getANIList(currentPage);
                    getFilters();
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="Home__MonitorOptions">
          <div className="Home__Children">
            {!!time && (
              <div
                className="Home__Monitoring"
                onClick={() => setMonitoring((oldValue) => !oldValue)}
              >
                <p>{monitoring ? "Desativar" : "Ativar"} Modo Monitoria</p>
                <OToogle status={monitoring} />
              </div>
            )}
            {lastUpdate && (
              <p className="Home__Monitoring LastUpdate">
                Último Update: <span>{lastUpdate || "--"}</span>
              </p>
            )}
          </div>
        </div>
        <AlertsSidebar />
      </section>
      {config && (
        <OModal
          title="Configurações Olos Ani Monitor"
          removeInfo
          onClose={() => setConfig(false)}
        >
          <div className="Config">
            <OTabs tabs={_parseTabs()} change={(tab) => setTabName(tab.id)} />
            <div className="Config__Container">{_configViews[tabName]}</div>
          </div>
        </OModal>
      )}
      {firstDateModal && (
        <OModal
          isHtml
          removeInfo
          name="Home__InfoAnatel"
          title={modalfirstDate[0]?.title}
          text={modalfirstDate[0]?.text}
          onClose={() => handleCloseModal()}
        />
      )}
      {secondDateModal && (
        <OModal
          isHtml
          removeInfo
          name="Home__InfoAnatel"
          title={modalSecondDate[0]?.title}
          text={modalSecondDate[0]?.text}
          onClose={() => handleCloseModal()}
        />
      )}
    </section>
  );
};
