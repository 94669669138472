import { ReactElement } from "react";
import "./OButtonIcon.scss";

type Props = {
  label: string;
  icon: ReactElement;
  onClick: () => void;
  disable?: boolean;
};

const OButtonIcon = (props: Props) => {
  const { icon, label, disable, onClick } = props;

  return (
    <div className={`OButtonIcon ${disable ? "OButtonIcon--disable" : ""} `} onClick={() => onClick()}>
      {icon}
      <span>{label}</span>
    </div>
  );
};

export default OButtonIcon;
