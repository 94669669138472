/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  OHeader,
} from "../../components";

import { useNavigate } from "react-router-dom";

import "./NotFound.scss";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <section className="NotFound">
      <OHeader />
      
      <main className="NotFound__Content">
        <div className="NotFound__Error">
          <h1 className="NotFound__PageTitle">ERRO 404</h1>
          <p>Página não encontrada!</p>
          <Button name="NotFound__Search" size="Small" label="Voltar para o início" onClick={() => {
              navigate('/');
          }} />
        </div>
      </main>
    </section>
  );
};
