/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from "react";
import { format } from "date-fns";

import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

import {
  Button,
  OHeader,
  OLoading,
  OSelect,
  OInputForm,
  OModal,
  OCheckbox,
  OTabs,
  OButtonIcon,
  OPagination,
  OToogle,
  OTableGrid,
} from "../../components";

import { Anis, AnisLive, PageCategory, Pagination, ParamsAmount } from "../../@types/OlosTypes";
import { dateBrToUs } from "../../utils/date";
import { ReactSelect, UserMenu } from "../../@types/DataTypes";
import { useDispatch } from "react-redux";
import { setNewSearchStatus } from "../../store/slices/slices";
import { useInterval } from "../../utils/useInterval";
import PageContent from "../Data.json";

import { useLocation, useNavigate } from "react-router-dom";
import { GeralConfig } from "../GeralConfig/GeralConfig";

import "./Console.scss";
import "./Config.scss";
import { getAllFilters, getExtractData, getLogs, getTotalLogs } from "../../services/Olos/OlosAPI";
import OGrid from "../../components/OAniGrid/OGrid";
import { AxiosResponse } from "axios";

type ANIFilters = {
  campaignid: [],
  contract: [],
  platformid: [],
  pstn: [],
  route: []
}

export const Console = () => {
  const {
    startDate: periodStart,
    time,
    pagination,
    canEdit,
  } = (window as any).config;

  const location = useLocation();
  const navigate = useNavigate();
  const [currentPageCategory, setCurrentPageCategory] = useState<PageCategory | undefined>();

  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);
  const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);

  const [pageData, setPageData] = useState<Anis[]>([]);
  const [extractData, setExtractData] = useState<{
    lines: number,
    total: number,
    shortCalls: number,
    regularCalls: number,
    shortCallsRate: number,
    regularCallsRate: number
  }>({
    lines: 0,
    total: 0,
    shortCalls: 0,
    regularCalls: 0,
    shortCallsRate: 0,
    regularCallsRate: 0
  });

  const [pageLiveData, setPageLiveData] = useState<any[]>([]);
  const [pageError, setPageError] = useState("");
  const [currentPage, setCurrentPage] = useState<Pagination>({page: 0, offset: 0});

  const [allAni, setAllAni] = useState<ReactSelect[]>([]);
  const [selectedAni, setSelectedAni] = useState<ReactSelect[]>([]);

  const [lastUpdate, setLastUpdate] = useState<string>("");

  const [loadingPage, setLoadingPage] = useState(false);

  const [monitoring, setMonitoring] = useState(false);
  const [config, setConfig] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [organization, setOrganization] = useState<ReactSelect[] | undefined>([]);

  const [rote, setRote] = useState<ReactSelect[] | undefined>([]);

  const [tabs, setTabs] = useState(PageContent.configMenu);
  const [tabName, setTabName] = useState("geral");

  const [totalElements, setTotalElements] = useState<number>(0);

  const [filters, setFilters] = useState<ANIFilters>({
    campaignid: [],
    contract: [],
    platformid: [],
    pstn: [],
    route: []
  });

  const [selectedPlatform, setSelectedPlatform] = useState<ReactSelect>();
  const [selectedContract, setSelectedContract] = useState<ReactSelect>();
  const [selectedRoute, setSelectedRoute] = useState<ReactSelect>();
  const [selectedANI, setSelectedANI] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<[{ column: string, value: string }] | []>([]);
  const [shouldResetPagination, setShouldResetPagination] = useState(false);

  const [responseError, setResponseError] = useState(false);

  const dispatch = useDispatch();

  const _resetConfig = () => {
    setSelectedAni([]);
    setSelectAll(false);
  };

  const getPagination = async () => {
    let currentPagination = currentPage;

    if (shouldResetPagination) {
      currentPagination = { page: 0, offset: 0 };
      setCurrentPage(currentPagination);
      setShouldResetPagination(false);
    } else {
      setCurrentPage(currentPagination);
    }
    
    const [startDate, endDate] = dateRange;

    const parsedStartDate = parseDate(startDate);
    const parsedEndDate = parseDate(endDate);

    const totalLogs = await getTotalLogs(parsedStartDate, parsedEndDate);

    console.log('totalLogs ', totalLogs.data)

    if ((totalLogs as AxiosResponse).status === 200) {
      setTotalElements(totalLogs.data.total);
      setResponseError(false);
    } else {
      // setLoadingPage(false);
      setPageError("Houve um erro ao recuperar os dados.")
      return setResponseError(true);
    }
  }

  const parseDate = (date: any)=> {
    if (date) {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    }

    return '';
  }

  const tableExtractDataColumns = [
    {
      label: "Data de inserção",
      id: 'insertedat',
      render: parseDate,
      // width: '160px'
      // width: '0.16494845360824742268041237113402fr'
    },
    {
      label: "Nome do arquivo",
      id: 'filename',
      breakWord: true
      // width: '310px'
      // width: '0.31958762886597938144329896907216fr'
    },
    {
      label: "Mensagem",
      id: 'message',
      // width: '250px'
      // width: '0.25773195876288659793814432989691fr'
    },
    {
      label: "Erro",
      id: 'iserror',
      // width: '100px',
      // width: '0.10309278350515463917525773195876fr',
      render: (error:string) => error === '0' ? 'Não' : 'Sim'
    },
    {
      label: "Total de linhas",
      // width: '150px',
      // width: '0.15463917525773195876288659793814fr',
      id: 'totalrows'
    }
  ]

  const tableResumeDataColumns = [
    {
      label: "Linhas importadas",
      id: 'lines',
      // render: parseDate,
      // width: '160px'
    },
    {
      label: "Total de ligações boas",
      id: 'regularCalls',
      // width: '250px'
    },
    {
      label: "Ligações boas (%)",
      // width: '150px',
      id: 'regularCallsRate',
      render: (percentage:number) => percentage + '%'
    },
    {
      label: "Total de ligações ruins",
      id: 'shortCalls',
      // width: '250px'
    },
    {
      label: "Ligações ruins (%)",
      id: 'shortCallsRate',
      // width: '160px',
      render: (percentage:number) => percentage + '%'
    }
  ]

  const getANIList = async (pagination: Pagination, filters: any = undefined) => {
    const currentFilters = filters || selectedFilters;

    setLoadingPage(true);
    // dispatch(setNewSearchStatus(true));

    const [startDate, endDate] = dateRange;

    const parsedStartDate = parseDate(startDate);
    const parsedEndDate = parseDate(endDate);

    const extractedData = await getExtractData(parsedStartDate, parsedEndDate);

    if ((extractedData as AxiosResponse).status === 200) {
      setExtractData(extractedData.data);
    }

    const logData = await getLogs(parsedStartDate, parsedEndDate, true, 50, currentPage.offset);

    const _date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
    setLastUpdate(_date);

    if ((logData as AxiosResponse).status === 200) {
      setPageLiveData(
        logData.data.map((item:any) => {
          return { ...item, openDetails: false };
        })
      );
    } else {
      setPageError("");
      setPageLiveData([]);
    }

    setLoadingPage(false);
    // dispatch(setNewSearchStatus(false));
  }

  const convertOptionsToObject = (options: []) => {
    return options.map((opt) => {
      return {
        value: opt,
        label: opt
      }
    });
  }

  const saveAndParseFilters = (filtersData: [])=> {
    let currentFilters = {...filters} as any;

    // console.log('saveAndParseFilters ', currentFilters);

    filtersData.map((filter)=> {
      const filterOptions = (filter as any).values;
      const parsedFilters = convertOptionsToObject(filterOptions);
      currentFilters[(filter as any).column] = parsedFilters;
    });

    setFilters(currentFilters);
  }

  useEffect(()=> {
    // console.log('filters', filters);
  }, [filters])

  const getFilters = async () => {
    if (responseError) return;

    try {
      const currentDate = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);

      setIsLoadingFilters(true);

      const allFilters = await getAllFilters(currentDate);

      saveAndParseFilters(allFilters.data);

      setIsLoadingFilters(false);
    } catch (error) {
      setIsLoadingFilters(false);
      console.log('TODO - Exibir erro ao buscar os filtros');
    }
  }

  const onChangeFilter = (resetAllFilters = false) => {
    setShouldResetPagination(true);

    if (resetAllFilters) {
      setFilters({
        campaignid: [],
        contract: [],
        platformid: [],
        pstn: [],
        route: []
      });
    }
  }
  
  const dateRangeIsValid = ()=> {
    const [startDate, endDate] = dateRange;
    return startDate && endDate;
  }

  useEffect(() => {
    if (dateRangeIsValid()) {
      getPagination();
      getANIList(currentPage);
    }

  }, [dateRange, currentPage]);

  useEffect(() => {
    if (monitoring) return setStartDate(new Date());
  }, [monitoring]);

  useEffect(() => {
    setTabs((old) =>
      old.map((item) => {
        return {
          ...item,
          activie: item.id === tabName,
        };
      })
    );
  }, [tabName]);

  const _getTimeMonitoring = () => {
    const _time = parseInt(time);
    const _localTime = localStorage.getItem("MonitoringTime");

    const _configTime = _time < 10 ? 10 : _time;
    const _storageTime = _localTime ? parseInt(_localTime) : _configTime;

    return _storageTime * 1000;
  };

  useInterval(
    async () => {

      await getANIList(currentPage);

      const _date = format(new Date(), "dd/MM/yyyy HH:mm:ss");
      setLastUpdate(_date);
    },
    monitoring ? _getTimeMonitoring() : null
  );

  const entryDateRules = {
    beforeDate: periodStart ? dateBrToUs(periodStart) : null,
    afterDate: new Date(),
  };

  const headerMenu: UserMenu[] = [
    {
      label: "Console",
      action: () => navigate("/console"),
    },
    // {
    //   label: "Configurações",
    //   action: () => {
    //     setConfig(true);
    //     setMonitoring(false);
    //   },
    // },
  ];

  const _gridSize = canEdit ? "min-content 0.6fr .9fr .9fr .9fr" : "0.6fr .9fr .9fr .9fr";
  const _gridGap = "10px";

  const _saveGeralConfig = (config: { time: string; pagination: string }) => {
    const _time = parseInt(config.time);
    localStorage.setItem("MonitoringTime", _time < 10 ? "10" : _time.toString());

    setConfig(false);
    return _resetConfig();
  };

  const _saveAniConfig = () => {
    // getAmountData();
  };

  const _updateAniList = (data: { ani: AnisLive; status: boolean }) => {
  };

  const _selectAll = (flag: boolean) => {
    const _anis = [...pageData];
    const _pageData = _anis.map((item) => {
      return {
        ...item,
        openDetails: flag,
      };
    });

    setPageData(_pageData);
    setSelectedAni(
      _pageData
        .filter((item) => item.openDetails)
        .map((item, index) => {
          return {
            label: item.Ani,
            value: index.toString(),
          };
        })
    );
  };

  const _configViews: { [key: string]: ReactElement } = {
    geral: <GeralConfig onSave={(pageData) => _saveGeralConfig(pageData)} onCancel={() => setConfig(false)} />,
    ani: (
      <div></div>
      // <AniConfig
      //   allAni={allAni}
      //   selectedsAni={selectedAni}
      //   onSave={() => _saveAniConfig()}
      //   onCancel={() => {
      //     _resetConfig();
      //     return setConfig(false);
      //   }}
      //   setSelectedAni={(anis) => setSelectedAni(anis)}
      // />
    ),
  };

  const _parseTabs = () => {
    return !canEdit ? tabs.filter((tab) => tab.id !== "ani") : tabs;
  };

  const disableFiltersExceptDatefilter = currentPageCategory?.type !== '/' || isLoadingFilters;

  const [dateStart, dateEnd] = dateRange;

  return (
    <section className="Console">
      <OHeader currentLink={location.pathname} userMenu={headerMenu} />
      
      <section className="Console__Main">
        <aside className="Console__Menu">
          {isLoadingFilters && <OLoading float />}
          <div className="Console__Menu__Content">
            <div className="Console__PageTitle">
              <span className="bold">Filtros</span>
            </div>

            <p className="Console__Title">Data</p>

            <div className="Console__Periodo">
              <div className="DatePicker">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  startDate={dateStart}
                  endDate={dateEnd}
                  onChange={(dates) => {
                    setDateRange(dates);
                    onChangeFilter(true);
                  }}
                  selectsRange
                  locale={ptBR}
                  disabled={monitoring || loadingPage}
                />
              </div>

              {/* <div className="Console__Field">
                <OSelect
                  label="Plataformas"
                  placeholder="Selecione uma opção"
                  options={filters.platformid || []}
                  value={selectedPlatform}
                  disable={monitoring || disableFiltersExceptDatefilter}
                  error={!organization}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedPlatform(_value);
                    onChangeFilter();
                  }}
                />
              </div>
              
              <div className="Console__Field">
                <OInputForm
                  label="ANI"
                  value={selectedANI}
                  disable={disableFiltersExceptDatefilter}
                  onChange={(value) => {
                    setSelectedANI(value)
                    onChangeFilter();
                  }}
                />
              </div>
              <div className="Console__Field">
                <OSelect
                  label="Contratos"
                  placeholder="Selecione uma opção"
                  options={filters.contract}
                  value={selectedContract}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedContract(_value);
                    onChangeFilter();
                  }}
                  disable={monitoring || disableFiltersExceptDatefilter}
                />
              </div>

              <div className="Console__Field">
                <OSelect
                  label="Rotas"
                  placeholder="Selecione uma ou mais opções"
                  options={filters.route || []}
                  value={selectedRoute}
                  error={!rote}
                  disable={monitoring || disableFiltersExceptDatefilter}
                  change={(value) => {
                    const _value = value as ReactSelect;
                    setSelectedRoute(_value);
                    onChangeFilter();
                  }}
                />
              </div> */}

              <Button name="Console__Search" label="Pesquisar" disabled={monitoring || loadingPage} onClick={() => {
                const filters = []

                if (selectedANI) filters.push({column: "ani", value: selectedANI})
                if (selectedContract) filters.push({column: "contract", value: selectedContract.value})
                if (selectedRoute) filters.push({column: "route", value: selectedRoute.value})
                if (selectedPlatform) filters.push({ column: "platformid", value: selectedPlatform.value })
                
                getANIList(pagination, filters)
              }} />
            </div>
          </div>
        </aside>
        {!!pageLiveData.length && (!loadingPage || monitoring) ? (
          <main className="Console__Content">
            {/* <div className="Console__Content__container"> */}
              <h1>Resumo das importações</h1>

              <div className="Console__Resume">
                {/* <div>Linhas importadas {extractData.lines}</div>
                <div>{extractData.total}</div>
                <div>Total de ligações curtas {extractData.shortCalls}</div>
                <div>Total de ligações boas {extractData.regularCalls}</div>
                <div>Porcentagem de ligações curtas {extractData.shortCallsRate}%</div>
                <div>Porcentagem de ligações boas {extractData.regularCallsRate}%</div> */}

                <OTableGrid
                  columns={tableResumeDataColumns}
                  data={[extractData]}
                />
              </div>

              <h1>Arquivos importados</h1>

              <div className="Console__TableData">
                <div className="Console__TableContent">
                  <OTableGrid
                    columns={tableExtractDataColumns}
                    data={pageLiveData}
                    useGridTemplate={false}
                  />
                  {/* {pageLiveData.map((item, index) => {
                    return (<p>{item.filename}</p>)
                  })} */}
                </div>

                {!!pagination && (
                  <OPagination
                    itemsPerPage={50}
                    items={totalElements}
                    currentPage={currentPage}
                    pageChange={(pagination) => {
                      setCurrentPage(pagination)
                    }}
                  />
                )}
              </div>
            {/* </div> */}
          </main>
        ) : (
          <div className="Console__Loading">
            {loadingPage ? (
              <OLoading float />
              ) : (
                <div className="Console__Error">
                  <p className={pageError ? "Console__Error" : ""}> {pageError || "Não foram encontrados dados"}</p>
                  <br/>
                  <Button name="Console__Search" size="Small" label="Tentar novamente" onClick={() => {
                      setResponseError(false);
                      getANIList(currentPage);
                      getFilters();
                  }} />
                </div>
            )}
          </div>
        )}
        {/* <div className="Console__MonitorOptions">
          <div className="Console__Children">
            {!!time && (
              <div className="Console__Monitoring" onClick={() => setMonitoring((oldValue) => !oldValue)}>
                <p>{monitoring ? "Desativar" : "Ativar"} Modo Monitoria</p>
                <OToogle status={monitoring} />
              </div>
            )}
            {lastUpdate && (
              <p className="Console__Monitoring LastUpdate">
                Último Update: <span>{lastUpdate || "--"}</span>
              </p>
            )}
          </div>
        </div> */}
        {/* <aside className="Console__Menu">
          {isLoadingFilters && <OLoading float />}
          <div className="Console__Menu__Content">
            <div className="Console__PageTitle">
              <span className="bold">Alertas</span>
            </div>
          </div>
        </aside> */}
      </section>
      {config && (
        <OModal title="Configurações Olos Ani Monitor" removeInfo onClose={() => setConfig(false)}>
          <div className="Config">
            <OTabs tabs={_parseTabs()} change={(tab) => setTabName(tab.id)} />
            <div className="Config__Container">{_configViews[tabName]}</div>
          </div>
        </OModal>
      )}
    </section>
  );
};
