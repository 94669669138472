import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserToken } from "../../@types/DataTypes";

export interface ConfigState {
  newSearch: boolean;
  tenant: string;
  tenantProviders: string;
  loginErrorMessage: string;
  authError: boolean;
  requestError: any;
  searchDate: Date;
  appStartURL: string;
  tenantData: any;
  isLoadingTenant: boolean;
  loadingTenantStatus: any;
  user: any;
  userToken: UserToken | undefined | null;
  userInfo: any;
  isConfiguringAmplify: boolean;
  isAmplifyConfigured: boolean;
  isHubListenerConfigured: boolean;
  isValidatingToken: boolean;
  isTokenValidated: boolean;
  alreadyCheckedUser: boolean;
}

const initialState: ConfigState = {
  newSearch: false,
  tenant: '',
  tenantProviders: '',
  loginErrorMessage: '',
  authError: false,
  requestError: undefined,
  searchDate: new Date(),
  appStartURL: '',
  tenantData: undefined,
  isLoadingTenant: false,
  loadingTenantStatus: undefined,
  user: undefined,
  userToken: undefined,
  userInfo: undefined,
  isConfiguringAmplify: false,
  isAmplifyConfigured: false,
  isHubListenerConfigured: false,
  isValidatingToken: true,
  isTokenValidated: false,
  alreadyCheckedUser: false,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setNewSearchStatus: (state, action: PayloadAction<boolean>) => void (state.newSearch = action.payload),
    setTenant: (state, action: PayloadAction<string>) => void (state.tenant = action.payload),
    setTenantProviders: (state, action: PayloadAction<string>) => void (state.tenantProviders = action.payload),
    setLoginErrorMessage: (state, action: PayloadAction<string>) => void (state.loginErrorMessage = action.payload),
    setAuthError: (state, action: PayloadAction<boolean>) => void (state.authError = action.payload),
    setRequestError: (state, action: PayloadAction<any>) => void (state.requestError = action.payload),
    setSearchDate: (state, action: PayloadAction<Date>) => void (state.searchDate = action.payload),
    setAppStartURL: (state, action: PayloadAction<any>) => void (state.appStartURL = action.payload),
    setTenantData: (state, action: PayloadAction<any>) => void (state.tenantData = action.payload),
    setIsLoadingTenant: (state, action: PayloadAction<any>) => void (state.isLoadingTenant = action.payload),
    setLoadingTenantStatus: (state, action: PayloadAction<any>) => void (state.loadingTenantStatus = action.payload),
    setUser: (state, action: PayloadAction<any>) => void (state.user = action.payload),
    setUserToken: (state, action: PayloadAction<any>) => void (state.userToken = action.payload),
    setUserInfo: (state, action: PayloadAction<any>) => void (state.userInfo = action.payload),
    setIsConfiguringAmplify: (state, action: PayloadAction<any>) => void (state.isConfiguringAmplify = action.payload),
    setIsAmplifyConfigured: (state, action: PayloadAction<any>) => void (state.isAmplifyConfigured = action.payload),
    setIsHubListenerConfigured: (state, action: PayloadAction<any>) => void (state.isHubListenerConfigured = action.payload),
    setIsValidatingToken: (state, action: PayloadAction<any>) => void (state.isValidatingToken = action.payload),
    setIsTokenValidated: (state, action: PayloadAction<any>) => void (state.isTokenValidated = action.payload),
    setAlreadyCheckedUser: (state, action: PayloadAction<any>) => void (state.alreadyCheckedUser = action.payload),
  },
});

export const { 
  setNewSearchStatus, 
  setTenant, 
  setTenantProviders, 
  setLoginErrorMessage, 
  setAuthError, 
  setRequestError,
  setSearchDate,
  setAppStartURL,
  setTenantData,
  setIsLoadingTenant,
  setLoadingTenantStatus,
  setUser,
  setUserToken,
  setUserInfo,
  setIsConfiguringAmplify,
  setIsAmplifyConfigured,
  setIsHubListenerConfigured,
  setIsValidatingToken,
  setIsTokenValidated,
  setAlreadyCheckedUser,
} = configSlice.actions;

export default configSlice.reducer;
