import { ReactComponent as Close } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Alert } from "../../assets/icons/alert.svg";

import "./OModal.scss";

type Props = {
  title?: string;
  text?: string;
  name?: string;
  removeInfo?: boolean;
  redAlert?: boolean
  children?: JSX.Element;
  onClose: () => void;
  isHtml?: boolean;
};

const OModal = (props: Props) => {
  const { title, text, onClose, children, name, removeInfo, redAlert , isHtml} = props;

  return (
    <div className={`OModal ${name || ""}`}>
      <div className="OModal__Container">
        <Close className="OModal__Close" onClick={() => onClose()} />
        {redAlert && <Alert className="OModal__Alert" />}
        {!removeInfo && <Info className="OModal__Info" />}
        {title && <h1>{title}</h1>}
        {text && (isHtml ? <p dangerouslySetInnerHTML={{__html: text}} /> : <p>{text}</p>)}
        {children && <div className="OModal__Children">{children}</div>}
      </div>
    </div>
  );
};

export default OModal;
