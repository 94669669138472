import Select, { StylesConfig } from "react-select";
import { ReactSelect } from "../../@types/DataTypes";
import { ReactComponent as Help } from "../../assets/icons/help.svg";

import "./OSelect.scss";

type Props = {
  label: string;
  placeholder?: string;
  options: ReactSelect[];
  value?: ReactSelect | ReactSelect[] | null;
  help?: boolean;
  isMulti?: boolean;
  disable?: boolean;
  name?: string;
  loading?: boolean;
  error?: boolean;
  helpClick?: () => void;
  change: (value: ReactSelect | ReactSelect[], name: any) => void;
};

const OSelect = (props: Props) => {
  const { label, options, change, value, help, helpClick, isMulti, placeholder, disable, name, loading, error } = props;

  const selectStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      borderColor: error ? "#d63643" : "#cccccc",
      boxShadow: "none",
      minHeight: "50px",
      borderWidth: error ? "4px" : "2px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "18px",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "18px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "18px",
    }),
    menuList: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <div className={`OSelect ${disable ? "OSelect--Disable" : ""} ${name || ""} ${loading ? "loading-input" : ""}`}>
      <div className="OSelect__Label">
        <span>{label}</span>
        {help && <Help className="OSelect__Help" onClick={() => helpClick && helpClick()} />}
      </div>
      <Select
        value={value || null}
        placeholder={placeholder || "Selecione"}
        options={options}
        styles={selectStyles}
        onChange={(opt) => change(opt as ReactSelect, name)}
        isClearable
        noOptionsMessage={() => <>Sem opções</>}
        isMulti={isMulti}
      />
    </div>
  );
};

export default OSelect;
