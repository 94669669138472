import { useState } from "react";
import Mask from "@this-empathy/javascript-mask";

import { Button, OInputForm } from "../../components";
import PageContent from "../Data.json";

import "./GeralConfig.scss";

type Props = {
  onSave: (pageData: { time: string; pagination: string }) => void;
  onCancel: () => void;
};

export const GeralConfig = (props: Props) => {
  const { onSave, onCancel } = props;
  const { time, pagination } = (window as any).config;

  const _getTimeMonitoring = () => {
    const _time = parseInt(time);
    const _localTime = localStorage.getItem("MonitoringTime");

    const _configTime = _time < 10 ? 10 : _time;
    const _storageTime = _localTime ? parseInt(_localTime) : _configTime;

    return _storageTime.toString();
  };

  const _getPaginationTotal = () => {
    const _page = pagination;
    const _localPage = localStorage.getItem("PaginationTotal");

    return _localPage || _page;
  };

  const [monitoringTime, setMonitoringTime] = useState<string>(_getTimeMonitoring());
  const [paginationTotal, setPaginationTotal] = useState<string>(_getPaginationTotal());

  return (
    <section className="GeralConfig">
      <div className="GeralConfig__TimeContainer">
        <p>Use a configuração abaixo para mudar o tempo que o Ani Monitor sera atualizado automaticamente</p>
        <div className="GeralConfig__TimeInput">
          <OInputForm
            label="Configurar tempo"
            value={monitoringTime}
            onChange={(value) => setMonitoringTime(Mask.number(value))}
          />
          <span>segundos</span>
        </div>
        {parseFloat(monitoringTime) < 10 && (
          <div className="GeralConfig__Error">O valor não pode ser menor que 10 segundos</div>
        )}
        <div className="GeralConfig__TimeOpts">
          {PageContent.timeOptions.map((time, index) => {
            return (
              <div
                key={index}
                className="GeralConfig__TimeOpt"
                onClick={() => setMonitoringTime(time.value.toString())}
              >
                {time.label}
              </div>
            );
          })}
        </div>
      </div>

      {pagination && <div className="GeralConfig__PageContainer">
        <p>Use a configuração abaixo para mudar o total da paginação</p>
        <div className="GeralConfig__PageInput">
          <OInputForm
            label="Configurar paginação"
            value={paginationTotal}
            onChange={(value) => setPaginationTotal(Mask.number(value))}
          />
        </div>
        {parseFloat(paginationTotal) <= 0 && (
          <div className="GeralConfig__Error">O valor não pode ser menor ou igaul a 0</div>
        )}
      </div>}

      <div className="GeralConfig__Controller">
        <Button
          disabled={parseFloat(monitoringTime) < 10 || parseFloat(paginationTotal) <= 0}
          label="Salvar"
          onClick={() => onSave({ time: monitoringTime, pagination: paginationTotal })}
        />
        <Button label="Cancelar" theme="Border" onClick={() => onCancel()} />
      </div>
    </section>
  );
};
