import jwt_decode from 'jwt-decode';
import { UserToken } from './../../@types/DataTypes';
import { store } from './../../store/index';
import axios, { AxiosRequestConfig } from "axios";
import { AnisDataType } from "../../@types/OlosTypes";
import { setAuthError, setIsTokenValidated, setIsValidatingToken, setRequestError, setUser, setUserInfo, setUserToken } from '../../store/slices/slices';

import {clearTimeout, setTimeout } from 'worker-timers';

const tenantId = () => {
  return store.getState().config.tenant;
}

export const OlosLiveAPI = axios.create({
  baseURL: `https://api${process.env.REACT_APP_ENV_BUILD}.olos.live/`,
  headers: {
    'tenant-id': tenantId(),
  }
});

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
}

OlosLiveAPI.interceptors.request.use(async (config) => {
  // intercept all requests to add authentication token

  const userToken:UserToken = store.getState().config.userToken;

  if (userToken && userToken.accessToken && config.url !== 'auth/token') {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${userToken.accessToken}`,
      'tenant-id': tenantId()
    };
  }

  return config;
});

OlosLiveAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config } = error;

    const errorCode = error?.response?.status;

    const hasRetry = config && config.retry;

    console.log('error:::::::::::::::::: ', error);

    if (errorCode === 403 && !hasRetry) {
      store.dispatch(setAuthError(true));
    }

    // && errorCode !== 503 && errorCode !== 504

    if ((errorCode !== 401 && errorCode !== 403 && errorCode !== 409) && errorCode >= 400 && errorCode <= 599) {
      if (!hasRetry) {
        store.dispatch(setRequestError(error));
      }
    }
    
    if (!hasRetry) {
      return Promise.reject(error);
    }

    config.retry -= 1

    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000)
    })

    return delayRetryRequest.then(() => OlosLiveAPI(config));
  }
);

const createUserToken = (token:any) => {
  const expiresAt = new Date(token.expires_in * 1000);

  const userToken:UserToken = {
    tokenType: token.token_type,
    accessToken: token.access_token,
    expiresIn: token.expires_in,
    refreshToken: token.refresh_token,
    expiresInRefreshToken: token.expires_in_refresh_token,
    expiresAt
  }

  return userToken;
}

export const getOlosToken = async (cognitoAuthToken: string) => {
  const headers = {
    'tenant-id': tenantId(),
    Authorization: `Bearer ${cognitoAuthToken}`
  }

  // console.log('headers', headers);

  // const response = await OlosLiveAPI.post('login/authenticate', { idpName: tenantProvider()}, { headers });
  const response = await OlosLiveAPI.post('auth/token', undefined, { ...globalConfig, headers });
  const data = response.data;

  console.log('getOlosToken', response, data);

  sessionStorage.setItem('userToken', JSON.stringify(data));

  const userToken = createUserToken(data);

  const expiresAt = userToken?.expiresAt;

  if (expiresAt) {
    scheduleRefreshToken(expiresAt, refreshOlosToken);
  }

  store.dispatch(setUserToken(userToken));

  return data;
}

export const checkSessionToken = () => {
  console.log('CHECK SESSION TOKEN ============')
  console.log('CHECK SESSION TOKEN ============')
  console.log('CHECK SESSION TOKEN ============')
  const sessionUserToken = sessionStorage.getItem("userToken") || null;
  const parsedUserToken = sessionUserToken ? JSON.parse(sessionUserToken) : null;
  let sessionTokenValid = false;

  if (parsedUserToken) {
    console.log('checkSessionToken userToken', parsedUserToken);

    const userToken = createUserToken(parsedUserToken);

    store.dispatch(setUserToken(userToken));

    const decodedToken = jwt_decode(userToken.accessToken);

    store.dispatch(setUserInfo(decodedToken));

    console.log('isTokenValid: ', isTokenValid());

    if (isTokenValid()) {
      const userToken:UserToken = store.getState().config.userToken;
      const expiresAt = userToken?.expiresAt;
      if (expiresAt) {
        scheduleRefreshToken(expiresAt, refreshOlosToken);
      }
      sessionTokenValid = true;
    }
  }

  if (!sessionTokenValid) {
    store.dispatch(setUser(null));
    store.dispatch(setUserToken(null));
    store.dispatch(setUserInfo(null));
  }

  store.dispatch(setIsTokenValidated(true));
  store.dispatch(setIsValidatingToken(false));

  return sessionTokenValid;
}

const isTokenValid = () => {
  const userToken:UserToken = store.getState().config.userToken;

  const expiresAt = userToken?.expiresAt;

  if (expiresAt) {
    return new Date().getTime() < expiresAt.getTime();
  }

  return false;
};

let tokenTimeout:any;

const scheduleRefreshToken = (expiresAt: Date, refreshToken:Function) => {
  console.log('scheduleRefreshToken expiresAt: ', expiresAt);
  const delay = expiresAt.getTime() - new Date().getTime() - 30000; // renova o token 30 segundos antes de expirar

  console.log('delay:::::::::::::::::: ', delay);

  const tokenTimeoutId = parseInt(localStorage.getItem("refreshTimeout") as string);

  if (tokenTimeoutId) clearTimeout(tokenTimeoutId);

  tokenTimeout = setTimeout(async () => {
    await refreshToken();

    const refreshedToken:UserToken = store.getState().config.userToken;

    const newExpiresAt = refreshedToken?.expiresAt;

    if (newExpiresAt) {
      scheduleRefreshToken(newExpiresAt, refreshOlosToken);
    }

  }, delay);

  console.log('tokenTimeout: ', tokenTimeout);

  localStorage.setItem("refreshTimeout", tokenTimeout);
};

export const refreshOlosToken = async () => {
  const userToken:UserToken = store.getState().config.userToken;

  const headers = {
    'tenant-id': tenantId(),
    Authorization: `Bearer ${userToken?.accessToken}`
  }

  const response = await OlosLiveAPI.post('auth/refresh-token', {refreshToken: userToken?.refreshToken }, { ...globalConfig, headers });
  console.log('refreshOlosToken', response, response.data);

  const data = response.data;

  sessionStorage.setItem('userToken', JSON.stringify(data));

  const newUserToken = createUserToken(data);

  store.dispatch(setUserToken(newUserToken));

  const decodedToken = jwt_decode(data.access_token);

  store.dispatch(setUserInfo(decodedToken));
}

/**
 * API that returns tenant login info like IDP
 * @param tenant 
 */
export const getTenantInfo = async (tenant: string) => {
  const headers = { 
    'tenant-id': tenant,
    'x-api-key': '3EwVYhMYbLQ0qx9ynESP8nhB0=oZeXfRsy=qRR/-Rw9gt5g6wAICC?mD6r88WxRu'
  };
  const response = await OlosLiveAPI.get('auth/tenant/provider', { ...globalConfig, headers });
  return response;
};

export const ping = async () => {
  const response = await OlosLiveAPI.get('monitor/ping');

  console.log('ping', response, response.data);

  return response;
}

/*
ani *
operadora *
contrato *
rota *
campanha *
plataforma 
*/

export const getTotalObjectsByColumn = async (date: string, filters:any = [], column: string | undefined):Promise<any> => {
  let response = await OlosLiveAPI.post(`monitor/total/${column}?date=${date}`, filters, globalConfig)

  return response;
}

export const getDataByColumnCategory = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0, filters:any = undefined, column: string | undefined):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/${column}?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, filters, globalConfig);
  } else {
    response = await OlosLiveAPI.post(`monitor/count/${column}?date=${date}?sort=desc`, filters, globalConfig);
  }

  return response;
}

export const getDataByANI = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0, filters:any = undefined):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/ani?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, filters, globalConfig);
  } else {
    response = await OlosLiveAPI.post(`monitor/count/ani?date=${date}?sort=desc`, filters, globalConfig);
  }

  return response;
}

export const getDataByPSTN = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/pstn?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, null, globalConfig)
  } else {
    response = await OlosLiveAPI.post(`monitor/count/pstn?date=${date}?sort=desc`, null, globalConfig)
  }

  return response;
}

export const getDataByContract = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/contract?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, null, globalConfig)
  } else {
    response = await OlosLiveAPI.post(`monitor/count/contract?date=${date}?sort=desc`, null, globalConfig)
  }

  return response;
}

export const getDataByRoute = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/route?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, null, globalConfig)
  } else {
    response = await OlosLiveAPI.post(`monitor/count/route?date=${date}?sort=desc`, null, globalConfig)
  }

  return response;
}

export const getDataByCampaignId = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/campaignid?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, null, globalConfig)
  } else {
    response = await OlosLiveAPI.post(`monitor/count/campaignid?date=${date}?sort=desc`, null, globalConfig)
  }

  return response;
}

export const getDataByPlatformId = async (date: string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.post(`monitor/count/platformid?date=${date}&limit=${limit}&offset=${offset}&sort=desc`, null, globalConfig)
  } else {
    response = await OlosLiveAPI.post(`monitor/count/platformid?date=${date}?sort=desc`, null, globalConfig)
  }

  return response;
}

export const getAllFilters = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/all?date=${date}`, globalConfig)

  return response;
}

export const getExtractData = async (startDate:string, endDate:string) => {
  const response = await OlosLiveAPI.get(`monitor/extract?from=${startDate}&to=${endDate}`, globalConfig)

  return response;
}

export const getFilterOptionsByANI = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/ani?date=${date}`, globalConfig)

  return response;
}

export const getFilterOptionsByPSTN = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/pstn?date=${date}`, globalConfig)

  return response;
}

export const getFilterOptionsByContract = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/contract?date=${date}`, globalConfig)

  return response;
}

export const getFilterOptionsByRoute = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/route?date=${date}`, globalConfig)

  return response;
}

export const getFilterOptionsByCampaignId = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/campaignid?date=${date}`, globalConfig)

  return response;
}
export const getFilterOptionsByPlatform = async (date:string) => {
  const response = await OlosLiveAPI.get(`monitor/list/platformid?date=${date}`, globalConfig)

  return response;
}

export const getTotalLogs = async (startDate:string, endDate:string):Promise<any> => {
  let response = await OlosLiveAPI.get(`monitor/log/total?from=${startDate}&to=${endDate}`, globalConfig)

  return response;
}

export const getLogs = async (startDate:string, endDate:string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.get(`monitor/log?from=${startDate}&to=${endDate}&limit=${limit}&offset=${offset}&sort=desc`, globalConfig);
  } else {
    response = await OlosLiveAPI.get(`monitor/log?from=${startDate}&to=${endDate}?sort=desc`, globalConfig);
  }

  return response;
}

export const getTotalAlerts = async (startDate:string, endDate:string):Promise<any> => {
  let response = await OlosLiveAPI.get(`monitor/alert/total?from=${startDate}&to=${endDate}`, globalConfig)

  return response;
}

export const getAlerts = async (startDate:string, endDate:string, paginated: boolean = false, limit: number = 10, offset: number = 0):Promise<AnisDataType> => {
  let response;

  if (paginated) {
    response = await OlosLiveAPI.get(`monitor/alert?from=${startDate}&to=${endDate}&limit=${limit}&offset=${offset}&sort=desc`, globalConfig);
  } else {
    response = await OlosLiveAPI.get(`monitor/alert?from=${startDate}&to=${endDate}?sort=desc`, globalConfig);
  }

  return response;
}

export const getLastAlerts = async (date:string) => {
  let response = await OlosLiveAPI.get(`monitor/alert/last`, globalConfig)

  return response;
}

export const getRules = async () => {
  let response = await OlosLiveAPI.get(`alert/api/rules`, globalConfig)

  return response;
}

export const updateAlertRule = async (ruleId: string, ruleData:any) => {
  let response = await OlosLiveAPI.post(`alert/api/rules/${ruleId}`, ruleData, globalConfig)

  return response;
}

export const createAlertRule = async (ruleData:any) => {
  let response = await OlosLiveAPI.post(`alert/api/rules`, ruleData, globalConfig)

  return response;
}

export const deleteAlertRule = async (ruleId: string) => {
  let response = await OlosLiveAPI.delete(`alert/api/rules/${ruleId}`, globalConfig)

  return response;
}

export const getAbusiveRules = async () => {
  let response = await OlosLiveAPI.get(`loader/`, globalConfig)

  return response;
}

export const updateAbusiveRule = async (ruleData:any) => {
  let response = await OlosLiveAPI.put(`loader/`, ruleData, globalConfig)

  return response;
}

export const createAbusiveRule = async (ruleData:any) => {
  let response = await OlosLiveAPI.post(`loader/`, ruleData, globalConfig)

  return response;
}

export const deleteAbusiveRule = async (ruleId: string) => {
  let response = await OlosLiveAPI.delete(`loader/id/${ruleId}`, globalConfig)

  return response;
}