import { Lighthouse } from "../../@types/OlosTypes";
import { stringToThousand } from "../../utils/string";
import "./OAniLiveMonitor.scss";

type Props = {
  percentage: number;
  lighthouse: Lighthouse;
  total: number;
  qtd: number;
};

const OAniLiveMonitor = (props: Props) => {
  const { percentage, lighthouse, total, qtd } = props;

  const fixed = (value: number) => {
    try {
      return stringToThousand(value.toString())
    } catch(error) {
      console.log('Error on stringToThousand value.toString() ->', value);
      return value;
    }
  };

  return (
    <div className="OAniLiveMonitor">
      <div className={`OAniLiveMonitor__Lighthouse OAniLiveMonitor__Lighthouse--${lighthouse}`}>{percentage}%</div>
      <div className="OAniLiveMonitor__Parallel">
        {fixed(total)}
      </div>
    </div>
  );
};

export default OAniLiveMonitor;
