import { useState } from "react";
import { ReactSelect } from "../../@types/DataTypes";

import {
  Button,
  OIconButton,
  OInput,
  OLoading,
  OSelect,
} from "../../components";
import { createAlertRule, deleteAlertRule, updateAlertRule } from "../../services/Olos/OlosAPI";

import "./AddEdit.scss";
import { convertBoolToString, convertStringToBoolean } from "../../utils/string";

type Props = {
  rule: any,
  editMode: true | false,
  onUpdate: ()=> void,
  onRequestClose: ()=> void,
}

export const AddEdit = (props:Props) => {
  const { rule, editMode, onUpdate, onRequestClose } = props;

  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ruleDeleted, setRuleDeleted] = useState(false);
  const [ruleDeletedError, setRuleDeletedError] = useState(false);
  const [ruleUpdated, setRuleUpdated] = useState(false);
  const [ruleUpdatedError, setRuleUpdatedError] = useState(false);
  const [ruleCreated, setRuleCreated] = useState(false);
  const [ruleCreatedError, setRuleCreatedError] = useState(false);
  const [ruleCreatedErrorResponse, setRuleCreatedErrorResponse] = useState<any>();
  const [ruleUpdatedErrorResponse, setRuleUpdatedErrorResponse] = useState<any>();
  const [ruleDeletedErrorResponse, setRuleDeletedErrorResponse] = useState<any>();

  const alertTypeOptions = [
    {
      label: "ANI",
      value: "ani"
    },
    {
      label: "Contrato",
      value: "contract"
    },
    {
      label: "Operadora",
      value: "pstn"
    },
    {
      label: "Campanha (ID)",
      value: "campaign"
    },
    {
      label: "Rota",
      value: "route"
    }
  ];

  const alertSubTypeOptions = [
    {
      label: "Preconnet",
      value: "preconnect"
    },
    {
      label: "Abusiva",
      value: "abusive"
    }
  ];

  const alertPeriodOptions = [
    {
      label: "Dia",
      value: "d"
    },
    {
      label: "Hora",
      value: "h"
    },
    {
      label: "Minuto",
      value: "m"
    }
  ];

  const alertFlagOptions = [
    {
      label: "Sim",
      value: "true"
    },
    {
      label: "Não",
      value: "false"
    }
  ];

  const [formData, setFormData] = useState<any>({
    alertType: editMode ? rule.alertType : '',
    alertSubType: editMode ? rule.alertSubType : '',
    alertValue: editMode ? rule.alertValue : '',
    minimumTotalCalls: editMode ? rule.minimumTotalCalls : '',
    maxAbusiveCalls: editMode ? rule.maxAbusiveCalls : '',
    maxAbusiveCallsPercent: editMode ? rule.maxAbusiveCallsPercent : '',
    safetyFactor: editMode ? rule.safetyFactor : '',
    unblockMaxAbusiveCalls: editMode ? rule.unblockMaxAbusiveCalls : '',
    unblockMaxAbusiveCallsPercent: editMode ? rule.unblockMaxAbusiveCallsPercent : '',
    fullPeriodBy: editMode ? rule.fullPeriodBy : '',
    unblockWhenPeriodChange: editMode ? rule.unblockWhenPeriodChange : '',
    active: editMode ? rule.active : '',
    platformId: editMode ? rule.platformId : '',
  });

  function getOptionByValue(value: any, options:any) {
    let selectedValue = value;

    const option = options.find((option: any)=> {
      return option.value === selectedValue;
    })

    return option;
  }

  const [selectedAlertType, setSelectedAlertType] = useState<ReactSelect>(editMode ? getOptionByValue(rule.alertType, alertTypeOptions) : undefined);
  const [selectedAlertSubType, setSelectedAlertSubType] = useState<ReactSelect>(editMode ? getOptionByValue(rule.alertSubType, alertSubTypeOptions) : undefined);
  const [selectedAlertPeriod, setSelectedAlertPeriod] = useState<ReactSelect>(editMode ? getOptionByValue(rule.fullPeriodBy, alertPeriodOptions) : undefined);
  const [selectedUnblockPeriodChange, setSelectedUnblockPeriodChange] = useState<ReactSelect>(editMode ? getOptionByValue(convertBoolToString(rule.unblockWhenPeriodChange), alertFlagOptions) : undefined);
  const [selectedActiveRuleStatus, setSelectedActiveRuleStatus] = useState<ReactSelect>(editMode ? getOptionByValue(convertBoolToString(rule.active), alertFlagOptions) : undefined);

  function changeFormData(value: any, name: any) {
    const currentData = {...formData};

    currentData[name] = value;

    setFormData(currentData);
  }

  function checkAlertType(type: string) {
    return type === formData.alertType;
  }

  function createEdit() {
    const data = {...formData};

    data.minimumTotalCalls = +data.minimumTotalCalls;
    data.maxAbusiveCalls = +data.maxAbusiveCalls;
    data.maxAbusiveCallsPercent = +data.maxAbusiveCallsPercent;
    data.safetyFactor = +data.safetyFactor;
    data.unblockMaxAbusiveCalls = +data.unblockMaxAbusiveCalls;
    data.unblockMaxAbusiveCallsPercent = +data.unblockMaxAbusiveCallsPercent;
    data.unblockWhenPeriodChange = convertStringToBoolean(data.unblockWhenPeriodChange);
    data.active = convertStringToBoolean(data.active);

    if (checkAlertType("ani") || checkAlertType("contract") || checkAlertType("pstn")) {
      data.platformId = null;
    }

    setLoading(true);

    if (editMode) {
      updateAlertRule(rule.ruleId, data).then((response)=> {
        if (response && response.data?.message === "Rule updated") {
          onUpdate();
          setRuleUpdated(true);
        } else {
          setRuleUpdatedError(true);
          setRuleUpdated(false);
        }
      }).catch((error)=> {
        setRuleUpdatedErrorResponse(error.response.data);
        setRuleUpdatedError(true);
        setRuleUpdated(false);
      }).finally(()=>{
        setLoading(false);
      });
    } else {
      createAlertRule(data).then((response)=> {
        if (response && response.data.message === "Rule created") {
          setRuleCreated(true);
          onUpdate();
        } else {
          setRuleCreatedError(true);
          setRuleCreated(false);
        }
      }).catch((error)=> {
        setRuleCreatedErrorResponse(error.response.data);
        setRuleCreatedError(true);
      }).finally(()=>{
        setLoading(false);
      });
    }
  }

  function deleteRule() {
    setLoading(true);

    deleteAlertRule(rule.ruleId).then((response)=> {
      if (response.data?.message) {
        setRuleDeleted(true);
        onUpdate();
      } else {
        setRuleDeleted(false);
        setRuleDeletedError(true);
      }
    }).catch((error)=> {
      setRuleDeletedError(true);
      setRuleDeletedErrorResponse(error.response.data);
    }).finally(()=>{
      setLoading(false);
    });
  }

  function parseRequestError(requestError: {error: Array<object> | string}) {
    const errorData = requestError.error;

    if (Array.isArray(errorData)) {
      return errorData.map((err: any)=> {
        return <p>{JSON.stringify(err.constraints)}</p>
      });
    } else {
      return <p>{errorData}</p>
    }
  }

  return (
    <section className="AddEdit">
      <section className="AddEdit__Main">
        { loading && <OLoading float /> }

        { showDeleteConfirmation && 
          <div className="AddEdit__StatusContainer">
            {!ruleDeleted && <p>Tem certeza que deseja excluir essa regra?</p>}

            { ruleDeleted && <p>Regra excluída com sucesso!</p>}

            { ruleDeleted && 
              <div className="AddEdit__StatusContainer__Buttons">
                <Button label="OK" size="Small" onClick={onRequestClose} />
              </div>
            }
            
            { !ruleDeleted && 
              <div className="AddEdit__StatusContainer__Buttons">
                <Button label="Não" theme="Danger" size="Small" onClick={()=> {
                  setShowDeleteConfirmation(false);
                }} />
                <Button label="Sim" size="Small" onClick={deleteRule} />
              </div>
            }
          </div>
        }

        { (ruleUpdated || ruleCreated) &&
          <div className="AddEdit__StatusContainer">
            <p>{ruleUpdated ? "Regra atualizada com sucesso!" : "Regra criada com sucesso!"}</p>
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={onRequestClose} />
            </div>
          </div>
        }

        { ruleUpdatedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao atualizar a regra!</p>

            { parseRequestError(ruleUpdatedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleUpdatedError(false)} />
            </div>
          </div>
        }

        { ruleCreatedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao criar a regra!</p>

            { parseRequestError(ruleCreatedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleCreatedError(false)} />
            </div>
          </div>
        }

        { ruleDeletedError &&
          <div className="AddEdit__StatusContainer">
            <p>Ocorreu um erro ao deletar a regra!</p>

            { parseRequestError(ruleDeletedErrorResponse) }
            <div className="AddEdit__StatusContainer__Buttons">
              <Button label="OK" size="Small" onClick={()=> setRuleDeletedError(false)} />
            </div>
          </div>
        }

        <h1>{editMode ? 'Editar regra' : 'Nova regra'}</h1>
        
        <div className="AddEdit__ContentContainer">
          <div className="AddEdit__FormContainer">
            <OSelect
              label="Tipo"
              name="alertType"
              options={alertTypeOptions}
              value={selectedAlertType}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedAlertType(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OSelect
              label="Subtipo"
              name="alertSubType"
              options={alertSubTypeOptions}
              value={selectedAlertSubType}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedAlertSubType(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OInput
              name="alertValue"
              label="Valor"
              type="text"
              value={formData.alertValue}
              required
              onChange={changeFormData}
            />

            <OInput
              name="minimumTotalCalls"
              label="Total de ligações mínimas"
              type="number"
              value={formData.minimumTotalCalls}
              required
              onChange={changeFormData}
            />

            <div className={`AddEdit__ANIFilters ${checkAlertType("ani") && "AddEdit__ANIFilters--show"}`}>
              <OInput
                name="maxAbusiveCalls"
                label="Limite de Bloqueio de chamadas abusivas"
                type="number"
                value={formData.maxAbusiveCalls}
                required
                onChange={changeFormData}
              />

              <OInput
                name="unblockMaxAbusiveCalls"
                label="Limite de Desbloq. de chamadas abusivas"
                type="number"
                value={formData.unblockMaxAbusiveCalls}
                required
                onChange={changeFormData}
              />
            </div>

            <div className={`AddEdit__ANIFilters ${!checkAlertType("ani") && "AddEdit__ANIFilters--show"}`}>

            <OInput
              name="maxAbusiveCallsPercent"
              label="(%) Total de ligações abusivas"
              type="number"
              step="0.01"
              value={formData.maxAbusiveCallsPercent}
              required
              onChange={changeFormData}
            />

            <OInput
              name="unblockMaxAbusiveCallsPercent"
              label="(%) Limite de Desbloq. de chamadas abusivas"
              type="number"
              step="0.01"
              value={formData.unblockMaxAbusiveCallsPercent}
              required
              onChange={changeFormData}
            />
            </div>

            <OInput
              name="safetyFactor"
              label="(%) Fator de segurança"
              type="number"
              step="0.01"
              value={formData.safetyFactor}
              required
              onChange={changeFormData}
            />

            <OSelect
              label="Período"
              name="fullPeriodBy"
              options={alertPeriodOptions}
              value={selectedAlertPeriod}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedAlertPeriod(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OSelect
              label="Desbloq. Na mudança de período"
              name="unblockWhenPeriodChange"
              options={alertFlagOptions}
              value={selectedUnblockPeriodChange}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedUnblockPeriodChange(_option);
                changeFormData(_option?.value || '', name);
              }}
            />

            <OSelect
              label="Ativa"
              name="active"
              options={alertFlagOptions}
              value={selectedActiveRuleStatus}
              change={(option, name)=> {
                const _option = option as ReactSelect;
                setSelectedActiveRuleStatus(_option);
                changeFormData(_option?.value || '', name);
              }}
            />
            
            <OInput
              name="platformId"
              label="Plataforma (Id)"
              type="text"
              value={formData.platformId || ''}
              required
              onChange={changeFormData}
            />
          </div>

          <div className="AddEdit__Footer">
            {editMode &&
              <OIconButton label="Excluir regra" icon="trash" danger={true} onClick={()=> {
                setShowDeleteConfirmation(true);
              }}/>
            }

            <Button label={editMode ? 'Salvar' : 'Enviar'} size="Small" onClick={createEdit} />
          </div>
        </div>
      </section>
    </section>
  );
};