import { Tab } from "../../@types/DataTypes";
import OTabItem from "../OTabItem/OTabItem";
import "./OTabs.scss";

type Props = {
  tabs: Tab[];
  change?: (tab: Tab) => void;
};

const OTabs = (props: Props) => {
  const { tabs, change } = props;

  return (
    <div className="OTabs">
      {tabs.map((tab, key) => (
        <OTabItem key={key} tab={tab} click={() => change && change(tab)} />
      ))}
    </div>
  );
};

export default OTabs;
