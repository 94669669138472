import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionItem } from "../../@types/DataTypes";
import { ReactComponent as NaoConectadas } from "../../assets/icons/naoConectadas.svg";

import "./OAction.scss";

type Props = {
  opt: ActionItem;
};

const OAction = (props: Props) => {
  const { opt } = props;
  const { label, children, icon, indicator, link, active } = opt;

  const navigate = useNavigate();
  const [arrowPosition, setArrowPosition] = useState("");
  const [subMenu, setSubMenu] = useState(false);

  const _labelRef = useRef<HTMLLIElement>(null);

  const _icons: { [key: string]: JSX.Element } = {
    naoConectadas: <NaoConectadas />,
  };

  useEffect(() => {
    const _pos = (_labelRef.current?.getBoundingClientRect().width || 40) / 2;
    setArrowPosition(`${_pos}px`);
  }, []);

  return (
    //<div className="OAction">
    <div className={`OAction ${active ? 'OAction__Active' : ''}`} onMouseEnter={() => setSubMenu(true)} onMouseLeave={() => setSubMenu(false)}>
      <div className="OAction__Label" onClick={() => link && navigate(link)}>
        <ul>
          {icon && <li className="OAction__Icon">{_icons[icon]}</li>}
          <li ref={_labelRef}>{label}</li>
          {indicator && <li className="OAction__Indicator">{indicator}</li>}
        </ul>
      </div>
      {children && (
        <div className="OAction__Children" style={{ display: subMenu ? "block" : "none" }}>
          <div className="OAction__Triangle" style={{ left: arrowPosition || "20px" }} />
          <div
            className="OAction__ChildrenContainer"
            style={{ gridTemplateColumns: `repeat(${Math.floor(children.length / 4)}, 1fr)` }}
          >
            {children.map((child, item) => {
              const { label, icon, link, description } = child;
              return (
                <div key={item} className="OAction__Sub" onClick={() => link && navigate(link)}>
                  {icon && <div className="OAction__SubIcon">{_icons[icon]}</div>}
                  <div className="OAction__SubLabel">
                    <div className="OAction__SubText">{label}</div>
                    <div className="OAction__SubDescription" style={{ visibility: description ? "visible" : "hidden" }}>
                      {description || "None"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OAction;
