// third party
import { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, OInput, OLoading } from "../../components";
import { ReactComponent as LogoOlos } from "../../assets/logo.svg";
import { getOlosToken } from "../../services/Olos/OlosAPI";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setLoginErrorMessage, setUser, setUserInfo } from "../../store/slices/slices";
import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";
import "./login.scss";

/**
 * Login
 * @returns <Login />
 */
export const Login = () => {
  console.log('|||||||||||||||||| RENDER LOGIN');
  const tenantData = useSelector((state: RootState) => state.config.tenantData);
  const isLoadingTenant = useSelector((state: RootState) => state.config.isLoadingTenant);
  const appStartURL = useSelector((state: RootState) => state.config.appStartURL);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[] | null>(null);
  const [newPasswordValidationError, setNewPasswordValidationError] = useState(false);
  const { version } = (window as any).config;
  const hasEmailLogin = isEmailLoginEnabled(tenantData);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.config.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const userToken = useSelector((state: RootState) => state.config.userToken);
  const loginErrorMessage = useSelector((state: RootState) => state.config.loginErrorMessage);
  let location = useLocation();

  // useEffect(()=> {
  //   if (user) {
  //     // console.log('user => ', user);
  
  //     // console.log('user.signInUserSession', user.signInUserSession);
  //     // console.log('user.signInUserSession', user.signInUserSession.idToken.jwtToken);
  //     // console.log('user.signInUserSession', user.signInUserSession.accessToken);

  //     if (user.signInUserSession?.idToken?.jwtToken && !userToken) {
  //       cognitoLogin();
  //     }
  //   }

  // }, [user])

  // useEffect(()=> {console.log('currentLocation:: ', location)}, [location])

  const onLoginProviderClick = async () => {
    setIsLoading(true);
    try {
      const response = await Auth.federatedSignIn({customProvider: 'olos-oidc'})

      console.log('login response: ', response);

    } catch (error) {
      console.log('login error ', error);
      setIsLoading(false);
    }
  }

  /**
   * cognito login
   * @returns 
   */
  const cognitoLogin = async () => {
    // clear error message
    setError("");

    if (user) {
      const idToken =  user.signInUserSession?.idToken?.jwtToken;
      const response = await getOlosToken(idToken);

      setIsLoading(false);

      const decodedToken = jwt_decode(response.access_token);
      dispatch(setUserInfo(decodedToken));

      return navigate((appStartURL === "/login" || appStartURL === '/admin') ? "/" : appStartURL);
    }
  };

/**
   * cognito login
   * @returns 
   */
const cognitoEmailLogin = async () => {
  // clear error message
  setError("");
  dispatch(setLoginErrorMessage(''));

  const user = await signInWithEmail();

  console.log('USER cognitoEmailLogin', user);

  if (user?.username) {
    dispatch(setUser(user));
  } else {
    setIsLoading(false);
  }


  // se user.challengeName = "NEW_PASSWORD_REQUIRED" mostra form de mudar password.

  if (user && user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
    setIsLoading(false);
    return setShowChangePassword(true);
  }

  // validate user object
  if (user) {
    const idToken =  user.signInUserSession?.idToken?.jwtToken;
    
    const response = await getOlosToken(idToken);

    setIsLoading(false);

    return navigate((appStartURL === "/login" || appStartURL === '/admin') ? "/" : appStartURL);
  }
};

  async function signInWithEmail() {
    if (validateEmailAndPassword()) {
      setIsLoading(true);

      try {
        return await Auth.signIn(userName, password);
      } catch (error) {
        console.log('error signing in', error);
        return error;
      }
    }
  }

  /**
   * validateEmailAndPassword
   * @returns result boolean
   */
  const validateEmailAndPassword = () => {
    if (userName === '' || password === '') {
      const messages = [];
      !userName && messages.push("Preencha o campo e-mail");
      !password && messages.push("Preencha o campo senha");
      setError(messages);
      return false;
    }
    return true;
  }

  useEffect(()=> {
    document.addEventListener("keydown", authLogin, false);
    
    return ()=> {
      document.removeEventListener("keydown", authLogin, false);
    }
  });

  function authLogin(event:KeyboardEvent) {
    if (event.key === 'Enter' && !isLoading) {
      cognitoEmailLogin();
    }
  }

  function reloadApp() {
    sessionStorage.removeItem("token");

    window.location.href = window.location.pathname;
  }

  function sendPasswordConfirmation() {
    setNewPasswordValidationError(false);

    if (validateNewPassword()) {
      setIsLoading(true);

      console.log('user before new password !!!!!', user);

      const { requiredAttributes } = user.challengeParam
      
      Auth.completeNewPassword(user, newPassword, requiredAttributes).then((response)=> {
        // setIsLoading(false);
        console.log('Auth.completeNewPassword::::::::::::::::::: ', JSON.stringify(response));
      }).catch((error)=> {
        setIsLoading(false);
        setError([error.message])
      })
    } else {
      setNewPasswordValidationError(true);
    }
  }

  function validateNewPassword() {
    console.log('validateNewPassword');
    return newPassword === newPasswordConfirmation && newPassword !== '';
  }

  function renderNewPasswordForm() {
    return (
      <div className="Login__NewPasswordForm">
        <p>Defina sua <strong>nova senha</strong> antes de prosseguir.</p>

        {/* <OInput error={currentPassword === ''} autofocus type="password" label="Senha Atual" required onChange={value => setCurrentPassword(value)} /> */}
        <OInput type="password" error={newPasswordValidationError} label="Nova Senha" required onChange={value => setNewPassword(value)} />
        <OInput error={!validateNewPassword() && newPasswordConfirmation !== '' || newPasswordValidationError} type="password" label="Confirmar Nova Senha" required onChange={value => setNewPasswordConfirmation(value)} />
        <Button onClick={() => { sendPasswordConfirmation(); }} label="Enviar" />
      </div>
    )
  }

  function renderLoginEmailForm() {
    return (
      <>
        <OInput autofocus type="text" label="E-mail" required onChange={value => setUserName(value)} value={userName} />
        <OInput type="password" label="Senha" required onChange={value => setPassword(value)} value={password} />

        <Button onClick={() => { cognitoEmailLogin(); }} label="Entrar" />
      </>
    )
  }

  if (userToken) {
    return <Navigate to="/" />
  }
    
  // console.log('TODO - Tratar erro de login quando o user não é cadastrado na aplicação apesar de haver um login válido no AD.')

  if (showChangePassword) {
    return (
      <section className="Login">
        {
          isLoading &&
          <div className="Login__Loading">
            <OLoading float />
          </div>
        }
        <div className="Login__Box">
          <div className="Login__Info">
            <h1>
              <span>Olos</span> Journey <span>X</span>
            </h1>
            {!!version &&
              <h2 className="Login__Version">v {version}</h2>
            }
          </div>
          <form className="Login__Form">
            {renderNewPasswordForm()}
            {!loading && error &&
                <span className="Login__Title--error">
                  {typeof(error) === "string"
                    ? error
                    : error.map(m => <span key={m}>{m}<br /></span>)
                  }
                </span>
              }
          </form>
          <div className="Login__LogoBox">
            <LogoOlos />
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="Login">
      {
        isLoading &&
        <div className="Login__Loading">
          <OLoading float />
        </div>
      }
      <div className="Login__Box">
        <div className="Login__Info">
          <h1>
            <span>Olos</span> Journey <span>X</span>
          </h1>
          {!!version &&
            <h2 className="Login__Version">v {version}</h2>
          }
        </div>
        <form className="Login__Form">
          {isLoadingTenant && <p>Carregando dados da aplicação...</p>}
          {!!hasEmailLogin && !requestError &&
            renderLoginEmailForm()
          }
          {
            !isLoadingTenant && !loading && !error && !requestError && location.pathname === '/admin' &&
            <div className="Login__ButtonSave">
              <div className="Login__ButtonSave__ProviderButtons">
                <div className="Login__ButtonSave__OtherOptions">
                </div>
                <Button onClick={() => { onLoginProviderClick(); }} label="Entrar com AD" />
              </div>
            </div>
          }
          {
            requestError && 
            <div className='Login__AWS_Error'>
              <p>{requestError}</p>
              <Button onClick={() => { reloadApp(); }} label="Recarregar" />
            </div>
          }
          {(!!loading || !!error) &&
            <div className="Login__Title">
              {!!loading &&
                <span>Validando Credenciais...</span>
              }
              {!loading && error &&
                <span className="Login__Title--error">
                  {typeof(error) === "string"
                    ? error
                    : error.map(m => <span key={m}>{m}<br /></span>)
                  }
                </span>
              }
            </div>
          }
          <div>
            {!loading && loginErrorMessage &&
              <span className="Login__Title--error">
                {loginErrorMessage}
              </span>
            }
          </div>
        </form>
        <div className="Login__LogoBox">
          <LogoOlos />
        </div>
      </div>
    </section>
  );
};

/**
 * isEmailLoginEnabled
 * @param tenantData 
 * @returns 
 */
export const isEmailLoginEnabled = (tenantData: any) => {
  return tenantData?.use_cognito_pool || false;
};

/**
 * getTenantProvider
 * @param tenantData 
 */
export const getTenantProviders = (tenantData: any): any => {
  // console.log('tenantData ', tenantData);
  const providers = tenantData?.apps[0].providers;

  return providers;
};
