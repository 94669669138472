import { Tab } from "../../@types/DataTypes";
import "./OTabItem.scss";

type Props = {
  tab: Tab;
  click?: (tab: Tab) => void;
};

const OTabItem = (props: Props) => {
  const { tab, click } = props;
  const { label, activie } = tab;

  return (
    <div className={`OTabItem ${activie ? "OTabItem--activie" : ""}`} onClick={() => click && click(tab)}>
      <div className={`OTabItem__Label ${activie ? "OTabItem__Label--activie" : ""}`}>{label}</div>
      {activie && <div className="OTabItem__Underline" />}
    </div>
  );
};

export default OTabItem;
