import ReactPaginate from "react-paginate";
import { ReactComponent as Next } from "../../assets/icons/next.svg";
import { ReactComponent as Previous } from "../../assets/icons/previus.svg";

import "./OPagination.scss";

type Props = {
  itemsPerPage: number;
  items: number;
  currentPage?: { page: number, offset: number };
  pageChange: ({ page, offset }: { page: number; offset: number }) => void;
};

const OPagination = (props: Props) => {
  const { itemsPerPage, items, pageChange, currentPage = {page: 0, offset: 10} } = props;
  const handlePageClick = (page: { selected: number }) => {
    const newOffset = (page.selected * itemsPerPage) % items;
    pageChange({ page: page.selected, offset: newOffset });
  };

  return (
    <div className="OPagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<Next />}
        onPageChange={(page) => handlePageClick(page)}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(items / itemsPerPage)}
        previousLabel={<Previous />}
        forcePage={currentPage.page}
      />
    </div>
  );
};

export default OPagination;
