import "./OCheckbox.scss";

type Props = {
  onChange: ({ activie, value }: { activie: boolean; value: string }) => void;
  label?: string;
  value: string;
  checked: boolean;
  textTransform?: "capitalize" | "lowercase" | "uppercase";
};

const OCheckbox = (props: Props) => {
  const { value, label, onChange, checked, textTransform } = props;

  // const [_flag, setFlag] = useState(checked)

  const transformText = {
    capitalize: (value: string) => value.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
    lowercase: (value: string) => value.toLowerCase(),
    uppercase: (value: string) => value.toUpperCase(),
  };

  return (
    <label className="container">
      {label  && <div className="label">{transformText[textTransform || "capitalize"](label)}</div>}
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={() => {
          // setFlag(!_flag)
          onChange({ activie: !checked, value });
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default OCheckbox;
