import "./OInputForm.scss";

type Props = {
  label: string;
  value: string;
  placeholder?: string;
  link?: string;
  isClearable?: boolean;
  disable?: boolean;
  onLinkClick?: () => void;
  onClearableClick?: () => void;
  onChange: (value: string) => void;
};

const OInputForm = (props: Props) => {
  const { disable, label, value, placeholder, onChange, link, onLinkClick, isClearable, onClearableClick } = props;

  return (
    <div className={`OInputForm ${disable ? "OInputForm--disable" : ""}`}>
      <label className="OInputForm__Label">{label}</label>
      <input
        className="OInputForm__Input"
        placeholder={placeholder || ""}
        type="text"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {link && (
        <span className="OInputForm__Link" onClick={() => onLinkClick && onLinkClick()}>
          {link}
        </span>
      )}
      {isClearable && !!value.length && (
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          className="OInputForm__Clearable"
          onClick={() => onClearableClick && onClearableClick()}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
      )}
    </div>
  );
};

export default OInputForm;
