import "./OIconButton.scss";

import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Config } from "../../assets/icons/config.svg";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

type Props = {
  label?: string;
  icon: "filter" | "config" | "back" | "plus" | "trash" | "edit";
  disable?: boolean;
  onClick?: () => void;
  danger?: boolean;
};

const OIconButton = (props: Props) => {
  const { icon, label, disable, onClick, danger = false } = props;

  const _renderIcon = {
    filter: <Filter className="OIconButton__Icon" />,
    config: <Config className="OIconButton__Icon" />,
    back: <Back className="OIconButton__Icon" />,
    plus: <Plus className="OIconButton__Icon" />,
    trash: <Trash className="OIconButton__Icon" />,
    edit: <Edit className="OIconButton__Icon" />,
  };

  return (
    <button 
      onClick={() => onClick && onClick()} 
      className={`OIconButton ${disable ? "OIconButton--disable" : ""} ${danger ? "OIconButton--danger" : ""} ${!label ? "OIconButton--no-label" : ""}`}>
      {label}
      {_renderIcon[icon]}
    </button>
  );
};

export default OIconButton;
