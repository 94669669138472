import "./OToogle.scss";

type Props = {
  status: boolean;
  theme?: 'orange' | 'white'
  change?: (value: boolean) => void;
};

const OToogle = (props: Props) => {
  const { status, change, theme } = props;

  return (
    <div className={`OToogle ${change ? 'OToogle--Change' : ''} OToogle--${theme || 'orange'}`}>
      <input type="checkbox" checked={status} onChange={() => change && change(!status)} />
    </div>
  );
};

export default OToogle;
