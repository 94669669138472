/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";

import "./App.scss";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Login, Home, Console, AlertsHistory, AlertsManager } from "./views";

import { ProtectedLayout } from "./components/ProtectedLayout/ProtectedLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlreadyCheckedUser,
  setAppStartURL,
  setIsAmplifyConfigured,
  setIsConfiguringAmplify,
  setIsHubListenerConfigured,
  setIsLoadingTenant,
  setIsTokenValidated,
  setIsValidatingToken,
  setLoadingTenantStatus,
  setLoginErrorMessage,
  setRequestError,
  setTenant,
  setTenantData,
  setUser,
  setUserInfo,
  setUserToken,
} from "./store/slices/slices";

import { RootState } from "./store";
import { Button, OModal } from "./components";
import { Amplify, Auth, Hub } from "aws-amplify";
import {
  checkSessionToken,
  getOlosToken,
  getTenantInfo,
} from "./services/Olos/OlosAPI";
import { AbusiveRulesManager } from "./views/AbusiveCalls/AbusiveRulesManager";
import { NotFound } from "./views/Home/NotFound";
import jwt_decode from "jwt-decode";
import { PageCategory, Pagination } from "./@types/OlosTypes";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const tenant = useSelector((state: RootState) => state.config.tenant);
  const tenantData = useSelector((state: RootState) => state.config.tenantData);

  const appStartURL = useSelector(
    (state: RootState) => state.config.appStartURL
  );

  const user = useSelector((state: RootState) => state.config.user);
  const userToken = useSelector((state: RootState) => state.config.userToken);
  const userInfo = useSelector((state: RootState) => state.config.userInfo);

  const isLoadingTenant = useSelector(
    (state: RootState) => state.config.isLoadingTenant
  );

  const isHubListenerConfigured = useSelector(
    (state: RootState) => state.config.isHubListenerConfigured
  );
  const isValidatingToken = useSelector(
    (state: RootState) => state.config.isValidatingToken
  );
  const isTokenValidated = useSelector(
    (state: RootState) => state.config.isTokenValidated
  );
  const isConfiguringAmplify = useSelector(
    (state: RootState) => state.config.isConfiguringAmplify
  );
  const isAmplifyConfigured = useSelector(
    (state: RootState) => state.config.isAmplifyConfigured
  );

  const authError = useSelector((state: RootState) => state.config.authError);
  const requestError = useSelector(
    (state: RootState) => state.config.requestError
  );
  const { modalDate } = (window as any)
    .config;

  const [dateModal, setDateModal] = useState(false);
  const [currentPageCategory, setCurrentPageCategory] = useState<
    PageCategory | undefined
  >();
  const [currentPage, setCurrentPage] = useState<Pagination>({
    page: 0,
    offset: 0,
  });

  const categories: PageCategory[] = [
    {
      type: "/",
      columnId: "ani",
      tableTitle: "ANI",
    },
  ];

  let isTenantChecked = useRef(false);
  let isHubConfigured = useRef(false);

  useEffect(() => {
    if (appStartURL === "") {
      dispatch(setAppStartURL(location.pathname));
    }
  });

  useEffect(() => {
    let tenantId = window.location.host.split(".")[0];

    if (tenantId.indexOf("localhost") >= 0) {
      tenantId = "acme";
    }

    if (
      !isTenantChecked.current &&
      tenantId !== "" &&
      !tenantData &&
      !isLoadingTenant
    ) {
      console.log("1 ============ SET TENANT AND GET TENANT DATA");
      getTenantData(tenantId);
      dispatch(setTenant(tenantId));
      isTenantChecked.current = true;
    }
  });

  const getTenantData = async (tenantName: string) => {
    // console.log('get tenant data');

    dispatch(setIsLoadingTenant(true));

    const data = await getTenantInfo(tenantName);

    console.log("2 ============ getTenantInfo");

    // console.log('carregou o tenant! ', data);

    if (data && data.status === 200) {
      dispatch(setLoadingTenantStatus(""));
      dispatch(setTenantData(data.data));
      dispatch(setIsLoadingTenant(false));
    } else {
      dispatch(setIsLoadingTenant(false));
      dispatch(
        setLoadingTenantStatus(
          "Ocorreu um erro ao carregar os dados do tenant!"
        )
      );
      // setError("Ocorreu um erro ao buscar os dados do tenant.");
      // console.log("Ocorreu um erro ao buscar os dados do tenant.");
    }
    // setLoading(false);
  };

  useEffect(() => {
    console.log(
      "tenantData: ",
      tenantData,
      isConfiguringAmplify,
      isAmplifyConfigured
    );

    if (tenantData && !isConfiguringAmplify && !isAmplifyConfigured) {
      const currentApp = tenantData.apps[0];

      // console.log('window location origin: ', window.location.origin);

      const config = {
        region: tenantData.region,
        userPoolId: tenantData.pool_id,
        userPoolWebClientId: currentApp.app_id,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        oauth: {
          domain: tenantData.oauth_domain,
          scope: currentApp.scope,
          redirectSignIn: window.location.origin,
          redirectSignOut: window.location.origin,
          responseType: "code",
          // redirectSignIn: 'http://localhost:3000/',
          // redirectSignOut: 'http://localhost:3000/'
        },
      };

      console.log(
        "3 ============ configureAmplify",
        "window ORIGIN: " + window.location.origin
      );

      dispatch(setIsConfiguringAmplify(true));
      Amplify.configure(config);
    }
  }, [tenantData]);

  useEffect(() => {
    if (currentPageCategory && currentPageCategory.type === location.pathname) {
      if (currentPageCategory?.type === "/") {
      }
    } else {
      setCurrentPageCategory(
        categories.find((category) => {
          return category.type === location.pathname;
        })
      );
    }
  }, [currentPageCategory, location, currentPage]);

  useEffect(() => {
    if (!isHubListenerConfigured && !isHubConfigured.current) {
      isHubConfigured.current = true;

      dispatch(setIsHubListenerConfigured(true));

      console.log("4 ============ configure Hub");
      const cancelHubToken = Hub.listen("auth", (response) => {
        const { payload } = response;
        const { event, data, message } = payload;

        const copiedData = { ...data };

        console.log("=== AWS Hub Events ===");
        console.log("=== event ", event);
        console.log("=== data ", data, JSON.stringify(copiedData));
        console.log("=== message ", message);

        switch (event) {
          // getUser().then(userData => {
          //   console.log('userData', userData);

          //   dispatch(setUser(userData))
          // }).catch((error)=> console.log('ERROR GER USER XXXXXX', error));
          // break;
          case "configured":
            console.log("amplify configured");
            dispatch(setIsAmplifyConfigured(true));
            dispatch(setIsConfiguringAmplify(false));
            break;
          case "signIn":
            console.log("amplify SignIn", data, message);

            if (!user) {
              getUser()
                .then((userData) => {
                  console.log("userData", userData);

                  dispatch(setUser(userData));
                  dispatch(setAlreadyCheckedUser(true));
                })
                .catch((error) => {
                  console.log("ERROR GER USER XXXXXX", error);
                  dispatch(setAlreadyCheckedUser(true));
                });
            }
            break;
          case "cognitoHostedUI":
            console.log("cognitoHostedUI");
            break;
          case "signOut":
            console.log("signOut aws");
            // sessionStorage.removeItem("token");
            dispatch(setAlreadyCheckedUser(true));
            dispatch(setUser(null));
            dispatch(setUserToken(null));
            dispatch(setUserInfo(null));
            // return navigate("/");
            break;
          case "signIn_failure":
          case "cognitoHostedUI_failure":
            console.log("Sign in failure", JSON.stringify(data), message);

            dispatch(
              setLoginErrorMessage(
                "Ocorreu um erro ao efetuar o login! Confira seu usuário e senha."
              )
            );

            dispatch(setUser(null));
            dispatch(setUserToken(null));
            dispatch(setUserInfo(null));
            // setError(data.message);
            break;
          case "tokenRefresh":
            console.log("AWS token refresh succeeded");
            break;
          case "tokenRefresh_failure":
            setRequestError(`${event}: ${data}`);
            break;
          case "confirmSignUp":
            console.log("AWS user confirmation successful");
            break;
          case "completeNewPassword_failure":
            console.log("AWS user did not complete new password flow");
            // setError(data.message);
            break;
          case "customOAuthState":
            console.log("AWS custom state returned from CognitoHosted UI");
            break;
        }
      });

      console.log("== 3 == Add AWS Listener");
    }
  });

  useEffect(() => {
    if (!checkSessionToken() && isAmplifyConfigured) {
      getUser()
        .then((userData) => {
          console.log("userData", userData);

          dispatch(setUser(userData));
          dispatch(setAlreadyCheckedUser(true));
        })
        .catch((error) => {
          console.log("ERROR GER USER XXXXXX", error);

          dispatch(setAlreadyCheckedUser(true));
        });
    }
  }, [isAmplifyConfigured]);

  // pegar token aws e gerar olostoken
  useEffect(() => {
    console.log("useEffect user change", {
      user,
      isValidatingToken,
      isTokenValidated,
      userToken,
    });
    if (user && !userToken) {
      console.log("call validateAmplifyToken");
      dispatch(setIsValidatingToken(true));
      validateAmplifyToken();
    }
    
    if (currentPageCategory?.tableTitle === "ANI") {
      openModal();
    }
  }, [user, isValidatingToken, isTokenValidated, userToken]);

  const validateAmplifyToken = async () => {
    const idToken = user.signInUserSession?.idToken?.jwtToken;
    const response = await getOlosToken(idToken);

    const decodedToken = jwt_decode(response.access_token);
    dispatch(setUserInfo(decodedToken));
    dispatch(setIsValidatingToken(false));
  };

  // useEffect(()=> {
  //   if (userToken && isTokenValidated) {
  //     navigate((appStartURL === "/login" || appStartURL === '/admin') ? "/" : appStartURL);
  //   }
  // }, [userToken])

  function getUser() {
    console.log("======================== get user ##########################");
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log("Not signed in");
        dispatch(setAlreadyCheckedUser(true));
      });
  }

  async function reloadApp() {
    sessionStorage.removeItem("token");

    await Auth.signOut()
      .then((response) => {
        console.log("================= RELOAD APP ==================");
        console.log(window.location.href, window.location.pathname);

        dispatch(setUser(null));
        dispatch(setUserToken(null));
        dispatch(setUserInfo(null));

        window.location.href = window.location.pathname;
      })
      .catch((err) => console.log("ERRO no logout: ", err));
  }

  function clearRequestError() {
    dispatch(setRequestError(undefined));
  }

  const openModal = () => {
    setDateModal(true);
  };

  const closeModal = () => {
    setDateModal(false);
  };

  
  return (
    <div className="App">
      {dateModal && (
        <OModal
        isHtml
          removeInfo
          name="Home__InfoAnatel"
          title={modalDate[0]?.title}
          text={modalDate[0]?.text}
          onClose={() => closeModal()}
        />
      )}
      {authError && (
        <OModal
          name="AuthErrorModal"
          redAlert={true}
          title="Atenção!"
          removeInfo
          onClose={reloadApp}
        >
          <div className="AuthError">
            <p>
              Ops! Ocorreu um erro com sua autenticação ou sua sessão expirou.
            </p>
            <p>Faça o login novamente para continuar!</p>

            <Button
              name="Home__Search"
              label="OK"
              size="Small"
              onClick={reloadApp}
            />
          </div>
        </OModal>
      )}
      {requestError && (
        <OModal
          name="RequestErrorModal"
          redAlert={true}
          title="Atenção!"
          removeInfo
          onClose={clearRequestError}
        >
          <div className="RequestErrorMessage">
            <h2>
              Ocorreu um erro ao consultar dados em algum serviço da aplicação.
            </h2>

            <div className="RequestErrorDescription">
              {JSON.stringify(requestError)}
            </div>

            <Button
              name="RequestErrorButton"
              label="OK"
              size="Small"
              onClick={clearRequestError}
            />
          </div>
        </OModal>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Login />} />

        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Home key="inicio" />} />
          <Route path="/operadoras" element={<Home key="operadoras" />} />
          <Route path="/contratos" element={<Home key="contratos" />} />
          <Route path="/rotas" element={<Home key="rotas" />} />
          <Route path="/campanhas" element={<Home key="campanhas" />} />
          <Route path="/plataformas" element={<Home key="plataformas" />} />
          <Route path="/console" element={<Console key="console" />} />
          <Route path="/alertas" element={<AlertsHistory key="alerts" />} />
          <Route
            path="/alertas/gerenciar"
            element={<AlertsManager key="alerts-management" />}
          />
          <Route
            path="/regras/gerenciar"
            element={<AbusiveRulesManager key="rules-management" />}
          />
          <Route path="*" element={<NotFound key="not-found" />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
