import { format } from "date-fns";
import { useEffect, useState } from "react";
import { getLastAlerts } from "../../services/Olos/OlosAPI";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import "./AlertsSidebar.scss";
import { useNavigate } from "react-router-dom";

export const AlertsSidebar = () => {
  const navigate = useNavigate();

  const [alerts, setAlerts] = useState([]);

  useEffect(()=> {
    const interval = setInterval(()=> {
      updateLastAlerts();
    }, 3000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const updateLastAlerts = async ()=> {
    const _date = format(new Date(), "yyyy-MM-dd");

    const alerts = await getLastAlerts(_date);
  
    if (alerts && alerts.data) {
      setAlerts(alerts.data);
    } 
  }

  const gotoAlerts = ()=> navigate('/alertas');

  return (
    <aside className="AlertsSidebar">
      <div className="AlertsSidebar__Menu__Content">
        <div className="AlertsSidebar__PageTitle">
          <span className="bold">Últimos Alertas</span>
        </div>

        {
          alerts.length === 0 && 
          <div className="AlertsSidebar__NoAlerts"><p>Não existem alertas a serem exibidos...</p></div>
        }
        {alerts.length > 0 && alerts.map((alert:any, index)=> {
            const today = new Date();
            const alertTime = new Date(alert.alert_time);
            const diff = (today.getTime() - alertTime.getTime()) / 3600000;
            const hoursDiffParam = 2;

            const isNewAlert = diff <= hoursDiffParam ? 'new-alert' : '';

            return (
              <div onClick={gotoAlerts} key={`alert${index}`} className={`AlertItem ${isNewAlert}`}>
                <ul>
                  {/* <li>{format(new Date(alert.insertedat), 'dd/MM/yyyy')}</li> */}
                  <li className={`AlertItem__Title`}><div className={`AlertItem__Icon ${alert.alert_action}`}><AlertIcon /></div> {format(new Date(alert.alert_time), 'dd/MM/yyyy - HH:mm')}</li>
                  {/* <li>{alert.platformid}</li> */}
                  <li><span>Tipo:</span> {alert.alert_type}</li>
                  <li><span>Valor:</span> {alert.alert_value}</li>
                  {/* <li>{alert.alert_time}</li> */}
                  <li><span>Ação:</span> {alert.alert_action}</li>
                  {/* <li>{alert.total_calls}</li>
                  <li>{alert.total_abusive_calls}</li> */}
                  {/* <li>{alert.abusive_calls_percent}</li> */}
                  {/* <li>{alert.alert_sub_type}</li> */}
                </ul>
              </div>
            )
          })}
      </div>
    </aside>
  )
}