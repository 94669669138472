import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Navigate, useLocation } from "react-router-dom";
import {
  Button,
  OHeader,
  OLoading,
  OTableGrid,
  OIconButton,
  OModal,
} from "../../components";
import { getAbusiveRules } from "../../services/Olos/OlosAPI";

import "./AbusiveRulesManager.scss";
import { AddEdit } from "./AddEdit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { checkUserRole } from "../../utils/auth";

export const AbusiveRulesManager = () => {
  const userRoles = useSelector((state: RootState) => state.config.userInfo?.roles);

  const location = useLocation();
  const [pageLiveData, setPageLiveData] = useState<any[]>([]);
  const [pageError, setPageError] = useState("");
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentRule, setCurrentRule] = useState<any>();
  const [editMode, setEditMode] = useState(false);
  
  const [responseErrorData, setResponseErrorData] = useState<any>();

  const tableExtractDataColumns = [
    {
      label: "Operadora",
      id: 'pstn',
      // render: (alertType:any, data:any)=> {
      //   return <div className={`AlertIcon ${data.alert_action}`}><AlertIcon /></div>
      // },
      // width: '160px'
    },
    {
      label: "Códigos",
      id: 'codes',
      render: (codesString:any)=> {
        let codesArray = JSON.parse(codesString);

        if (typeof(codesArray) === 'string') {
          codesArray = JSON.parse(codesArray);
        }
        
        return (
          <div className="OTableGrid__CodesContainer">
           {codesArray.map((code:any, index:any)=> <span key={index} className="OTableGrid__Code">{code}</span>)}
          </div>
        );
      },
    },
    // {
    //   label: "Data",
    //   id: 'insertedat',
    //   render: (alertTime:any)=> format(new Date(alertTime), 'dd/MM/yyyy - HH:mm')
    // },
    {
      label: "Sinalização",
      id: 'signaling',
      // width: '250px'
    },
    // {
    //   label: "Ativa",
    //   id: 'active',
    //   // render: (error:string) => error === '0' ? 'Não' : 'Sim'
    // },
    {
      label: "",
      id: 'edit',
      render: (id:any, item:any)=> {
        return (
          <OIconButton icon="edit" onClick={()=> editItem(item)} />
          // <button onClick={()=> editItem(item)}>Editar</button>
        )
      }
    },
    // {
    //   label: "",
    //   id: 'delete',
    //   render: (id:any, item:any)=> {
    //     return (
    //       <button onClick={()=> console.log('->>>> ITEM', item)}>Excluir</button>
    //     )
    //   }
    // }
  ]

  function parseCodes(codes:string) {
    let parsedCodes:Array<any> = [];

    try {
      parsedCodes = JSON.parse(JSON.parse(codes));
    } catch (error) {
      console.log('parseCodes error', error);
    }

    console.log({parsedCodes});

    return parsedCodes.join(',');
  }

  function editItem(item:any) {
    setCurrentRule(item);
    setShowEditModal(true);
    setEditMode(true);
  }

  const getAbusiveRulesData = async () => {
    setLoadingAlerts(true);

    const alertsData = await getAbusiveRules().catch((error)=> {
      return error;
    });

    console.log('alertsData', alertsData);

    if ((alertsData as AxiosResponse).status === 200) {
      console.log('all alerts', alertsData);

      setResponseErrorData(null);
      setPageLiveData(
        alertsData.data.map((item:any) => {
          return { ...item, openDetails: false };
        })
      );
    } else {
      // setResponseErrorData(alertsData.response.data);
      setResponseErrorData("");
      setPageError("");
      setPageLiveData([]);
    }

    setLoadingAlerts(false);
  }

  useEffect(() => {
    getAbusiveRulesData();
  }, []);

  if (!checkUserRole("supervisor", userRoles)) {
    return <Navigate to="/" />
  }

  return (
    <section className="AbusiveRulesManager">
      { responseErrorData && 
        <OModal name="ErrorModal" removeInfo onClose={()=> setResponseErrorData(null)}>
          <div className="ErrorModal__Content">
            <p>Ocorreu um erro ao recuperar os dados!</p>
            <p>{responseErrorData.error}</p>

            <Button size="Small" label="OK" onClick={() => {
              setResponseErrorData(null);
            }} />
          </div>
        </OModal>
      }

      {showEditModal && 
        <OModal name="AddEditModal" removeInfo onClose={()=> setShowEditModal(false)}>
          <AddEdit
            rule={currentRule}
            editMode={editMode}
            onUpdate={getAbusiveRulesData}
            onRequestClose={()=> {setShowEditModal(false)}}
          />
        </OModal>
      }
      <OHeader currentLink={location.pathname} />
      
      <section className="AbusiveRulesManager__Main">
        { loadingAlerts && <OLoading float /> }

        <main className="AbusiveRulesManager__Content">
          <div className="AbusiveRulesManager__Content__container">

            <div className="AbusiveRulesManager__HeaderContent">
              <OIconButton label="Adicionar nova regra" icon="plus" onClick={()=> {
                setEditMode(false);
                setShowEditModal(true);
              }}/>
            </div>

            <h1>Regras de Chamadas Abusivas</h1>

            { !pageLiveData.length &&
              <div className="AbusiveRulesManager__Loading">
                <div className="AbusiveRulesManager__Error">
                  <p className={pageError ? "AbusiveRulesManager__Error" : ""}> {pageError || "Nenhuma regra encontrada."}</p>
                  <br/>
                  <Button size="Small" label="Tentar novamente" onClick={() => {
                      getAbusiveRulesData();
                  }} />
                </div>
              </div>
            }
            
            { !!pageLiveData.length && 
              <div className="AbusiveRulesManager__TableData">
                <div className="AbusiveRulesManager__TableContent">
                  <OTableGrid
                    columns={tableExtractDataColumns}
                    data={pageLiveData}
                    useGridTemplate={false}
                    // onLineClick={(line)=> {console.log(line)}}
                  />
                </div>
              </div>
            }
          </div>
        </main>
      </section>
    </section>
  );
};