import { Navigate, useNavigate, useOutlet } from "react-router-dom"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import OLoading from "../OLoading/OLoading";

export const ProtectedLayout = () => {
  const outlet = useOutlet();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.config.user);
  const userToken = useSelector((state: RootState) => state.config.userToken);
  const isValidatingToken = useSelector((state: RootState) => state.config.isValidatingToken);
  const isAmplifyConfigured = useSelector((state: RootState) => state.config.isAmplifyConfigured);
  const isHubListenerConfigured = useSelector((state: RootState) => state.config.isHubListenerConfigured);
  const alreadyCheckedUser = useSelector((state: RootState) => state.config.alreadyCheckedUser);
  const isConfiguringAmplify = useSelector((state: RootState) => state.config.isConfiguringAmplify);
  const isTokenValidated = useSelector((state: RootState) => state.config.isTokenValidated);
  const mock = (window as any).config.mock;
  
  // console.log('ProtectedLayout: ', {userToken}, {isValidatingToken}, {user}, {isAmplifyConfigured}, {isHubListenerConfigured});

  // if (!userToken && !isValidatingToken && !user && isAmplifyConfigured && isHubListenerConfigured) {
  //   return <Navigate to="/login" />
  // }
  
  // else if (isValidatingToken) {
  //   return <OLoading float />
  // }
  console.log('=================');
  console.log(
    {userToken}, 
    {user}, 
    {alreadyCheckedUser}, 
    {isValidatingToken}, 
    {isConfiguringAmplify}, 
    {isTokenValidated}, 
    {isHubListenerConfigured}
    );
  console.log('=================');
  
  if (!mock) {
    if (!userToken && (user || !alreadyCheckedUser || isValidatingToken || isConfiguringAmplify || !isHubListenerConfigured )) {
      console.log('RENDER LOADING ======================')
      console.log('RENDER LOADING ======================')
      console.log('RENDER LOADING ======================')
      return <OLoading float />
    }

    if (!userToken && !user) {
      console.log('NAVIGATE TO LOGIN ======================')
      console.log('NAVIGATE TO LOGIN ======================')
      console.log('NAVIGATE TO LOGIN ======================')
      return <Navigate to="/login" />
    }
  }

  // if (!userToken && !isValidatingToken && !user && isAmplifyConfigured && isHubListenerConfigured) {
  //   // return <p>Checando credenciais...</p>
  //   return <Navigate to="/login" />
  // }

  // if (isValidatingToken) {
  //   return (
  //     <OLoading float />
  //   )
  // }

  return (
    <div>
      {outlet}
    </div>
  )
}