import { stringToThousand } from "../../utils/string";

export const ColumnsCommun = [
  {
    label: "Id",
    id: "Id",
    key: 1,
  },

  {
    label: "Descrição",
    id: "Description",
    key: 2,
  },
  {
    label: "Total",
    id: "Total",
    key: 3,
    render: (total: number) => {
      return total ? stringToThousand(total.toString()) : '0';
    },
  },
  {
    label: "Total Maior 4",
    id: "Total_Maior_4",
    key: 4,
    render: (total: number) => {
      return total ? stringToThousand(total.toString()) : '0';
    },
  },
  {
    label: "Total Menor 4",
    id: "Total_Menor_4",
    key: 5,
    render: (total: number) => {
      return total ? stringToThousand(total.toString()) : '0';
    },
  },
];
