import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import OAniMonitor from "../OAniMonitor/OAniMonitor";
import OTableGrid from "../OTableGrid/OTableGrid";
import OCheckbox from "../OCheckbox/OCheckbox";

import { Anis, AnisLive, GroupByEnum, ParamsAmount } from "../../@types/OlosTypes";
import { ColumnsCommun } from "./TableRender";
import { RootState } from "../../store";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { getLighthouseColor } from "../../utils/lighthouse";

import "./OAniGrid.scss";
import OAniLiveMonitor from "../OAniMonitor/OAniLiveMonitor";
import { stringToThousand } from "../../utils/string";

type TabName = "campaign" | "route" | "disposition" | "origin";
type Tab = {
  name: string;
  id: TabName;
  enum: GroupByEnum;
  activie?: boolean;
};

type Props = {
  data: AnisLive;
  gridArea: string;
  gridGap?: string;
  period: Date;
  filter?: ParamsAmount;
  canEdit: boolean;
  onLineSelect?: ({ ani, status }: { ani: AnisLive; status: boolean }) => void;
};

const OGrid = (props: Props) => {
  const { data, gridArea, gridGap, period, filter, canEdit, onLineSelect } = props;
  const {
    column,
    regularCalls,
    regularCallsRate,
    shortCalls,
    shortCallsRate,
    total,
    value,
    openDetails
  } = data;

  const newSearch = useSelector((state: RootState) => state.config.newSearch);

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailsTab, setDetailsTab] = useState<TabName | null>(null);

  const [tableData, setTableData] = useState<any[]>([]);
  const [loadingGroup, setLoadingGroup] = useState(false);

  const [tabs, setTabs] = useState<Tab[]>([
    {
      id: "campaign",
      name: "Campanha",
      activie: true,
      enum: "0",
    },
    {
      id: "route",
      name: "Rota",
      activie: false,
      enum: "1",
    },
    {
      id: "disposition",
      name: "Tabulação",
      activie: false,
      enum: "2",
    },
    {
      id: "origin",
      name: "Origem do Desligamento",
      activie: false,
      enum: "3",
    },
  ]);

  const _getMessage = () =>
    loadingGroup
      ? "Carregando..."
      : `Não foram encontrados informações de ${tabs.find((item) => item.id === detailsTab)?.name}`;

  const _tables: { [key in TabName]: JSX.Element } = {
    campaign: <OTableGrid key="campaign" columns={ColumnsCommun} data={tableData} noDataMessage={_getMessage()} />,
    disposition: (
      <OTableGrid key="disposition" columns={ColumnsCommun} data={tableData} noDataMessage={_getMessage()} />
    ),
    origin: <OTableGrid key="origin" columns={ColumnsCommun} data={tableData} noDataMessage={_getMessage()} />,
    route: <OTableGrid key="route" columns={ColumnsCommun} data={tableData} noDataMessage={_getMessage()} />,
  };

  const _tabClick = (tab: Tab) => {
    setDetailsOpen(true);
    setDetailsTab(tab.id);
    setTabs((oldTabs) => {
      return oldTabs.map((olsTab) => {
        return {
          ...olsTab,
          activie: olsTab.id === tab.id,
        };
      });
    });
  };

  const _resetMenu = (name: string) => {
    return setTabs((oldTabs) => {
      return oldTabs.map((tab) => {
        return {
          ...tab,
          activie: tab.id === name,
        };
      });
    });
  };

  // const _findGroup = (tab?: Tab) => {
  //   const _tab = tab || tabs[0];
  //   setLoadingGroup(true);
  //   _tabClick(_tab);
  //   GetGroupBy(
  //     {
  //       ani: Ani,
  //       date: period,
  //       groupBy: _tab.enum,
  //     },
  //     filter
  //   )
  //     .then((res) => {
  //       setTableData(res);
  //     })
  //     .catch((error) => {
  //       console.error("ERROR API GetGroupBy", error);
  //       setTableData([]);
  //     })
  //     .finally(() => setLoadingGroup(false));
  // };

  useEffect(() => {
    if (!newSearch) {
      _resetMenu("campaign");
      return setDetailsOpen(newSearch);
    }
  }, [newSearch]);

  // useEffect(() => {
  //   if (!detailsOpen) return;
  //   _findGroup();
  // }, [detailsOpen]);

  return (
    // <div className={`OAniGrid ${detailsOpen ? "OAniGrid--open" : ""} ${Bloqueado ? "OAniGrid--Red" : ""}`}>
    <div className={`OAniGrid ${detailsOpen ? "OAniGrid--open" : ""} ${false ? "OAniGrid--Red" : ""}`}>
      <div className="OAniGrid__Content" style={{ gridTemplateColumns: gridArea, gap: gridGap }}>
        {canEdit && (
          <OCheckbox
            checked={openDetails || false}
            value={value}
            onChange={(value) => onLineSelect && onLineSelect({ ani: data, status: value.activie })}
          />
        )}
        <div className="OAniGrid__Label">{value}</div>
        <div className="OAniGrid__Label">{stringToThousand(total.toString())}</div>

        {/* <OAniLiveMonitor
          lighthouse={getLighthouseColor(shortCallsRate)}
          total={total}
          percentage={shortCallsRate}
          qtd={total}
        /> */}
        <OAniLiveMonitor
          lighthouse={getLighthouseColor(0)}
          total={regularCalls}
          percentage={regularCallsRate}
          qtd={regularCalls}
        />
        <OAniLiveMonitor
          lighthouse={getLighthouseColor(shortCallsRate)}
          total={shortCalls}
          percentage={shortCallsRate}
          qtd={shortCalls}
        />
        {/* <OAniMonitor
          lighthouse={getLighthouseColor(PorcentagemTotal_Maior_4)}
          total={TotalLimite}
          percentage={PorcentagemTotal_Maior_4}
          qtd={Total_Maior_4}
        />
        <OAniMonitor
          lighthouse={getLighthouseColor(PorcentagemTotal_Menor_4)}
          total={TotalLimite}
          percentage={PorcentagemTotal_Menor_4}
          qtd={Total_Menor_4}
        /> */}
        {/* <div className="OAniGrid__Lock">
          <div className={`OAniGrid__LockCircle OAniGrid__LockCircle--${Bloqueado ? "Red" : "Green"}`} />
        </div>
        <div>{DataBloqueio ? format(new Date(DataBloqueio), "dd/MM/yyyy hh:mm:ss") : "--"}</div> */}
        {/* <ul className="OAniGrid__SeeDetails">
          <li className="OAniGrid__SeeDetails__Label" onClick={() => setDetailsOpen((oldFlag) => !oldFlag)}>
            {detailsOpen ? "Fechar Detalhes" : "Ver Detalhes"}
          </li>
        </ul> */}
      </div>
      <div className={`OAniGrid__Details ${detailsOpen ? "OAniGrid__Details--open" : ""}`}>
        <div className="OAniGrid__DetailsContent" style={{ gridTemplateColumns: gridArea }}>
          <div className="OAniGrid__DetailsMenu">
            <ul>
              {tabs.map((tab, key) => (
                <li
                  key={key}
                  className={tab.activie ? "OAniGrid__DetailsMenuActive" : ""}
                  // onClick={() => _findGroup(tab)}
                >
                  {tab.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="OAniGrid__DetailsTable">{_tables[detailsTab || "campaign"]}</div>
        </div>
      </div>
    </div>
  );
};

export default OGrid;
