import jwt_decode, { JwtPayload } from "jwt-decode";

export function checkUserRole(roleType: string | Array<string>, userRoles: Array<string>) {
  if (!userRoles) return false;

  if (Array.isArray(roleType)) {
    return roleType.every(value => {
      return userRoles.includes(value);
    })
  } else {
    return userRoles.includes(roleType);
  }
}

/**
 * @author Charles Markovich
 * @summary  Check if JWT is expired
 * @description A global validator utility to share validation rules across all apps for a given project.
 * @public
 */

export const isJwtExpired = (token: string) => {
  if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

  let isJwtExpired = false;
  const { exp } = jwt_decode<JwtPayload>(token);
  const currentTime = new Date().getTime() / 1000;

  if (exp && currentTime > exp) {
    isJwtExpired = true;
  } else {
    throw new Error('Invalid expiration: ', exp as any);
  }

  return isJwtExpired;
}