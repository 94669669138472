import { useNavigate } from "react-router-dom";
import { UserMenu } from "../../@types/DataTypes";
import { ReactComponent as LogoOlos } from "../../assets/logo.svg";

import OUser from "../OUser/OUser";
import OAction from "../OAction/OAction";

import Menu from "../../data/menu.json";

import "./OHeader.scss";
import { Auth } from "aws-amplify";
import { checkUserRole } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setUser, setUserToken } from "../../store/slices/slices";

type Props = {
  userMenu?: UserMenu[];
  currentLink?: string;
};

const OHeader = (props: Props) => {
  const { userMenu, currentLink } = props;
  const userRoles = useSelector((state: RootState) => state.config.userInfo?.roles);
  const dispatch = useDispatch();

  const headerMenu: UserMenu[] = [
    {
      label: "Console",
      action: () => navigate("/console"),
    },
    {
      label: "Alertas",
      action: () => navigate("/alertas"),
    }
    // {
    //   label: "Configurações",
    //   action: () => {
    //     setConfig(true);
    //     setMonitoring(false);
    //   },
    // },
  ];

  if (checkUserRole("supervisor", userRoles)) {
    headerMenu.push({
      label: "Configuração de Alertas",
      action: () => navigate("/alertas/gerenciar"),
    });

    headerMenu.push({
      label: "Regras",
      action: () => navigate("/regras/gerenciar"),
    })
  }

  const navigate = useNavigate();

  const userName = sessionStorage.getItem("userName") || "Olos";

  const _logOut = async () => {
    console.log('LOGOUT::::::::::: ')
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("userToken", "");
    
    const logoutResponse = await Auth.signOut().then(async (response)=> {
      console.log('logout resp ', response);
      
      await Auth.currentAuthenticatedUser()
          .then((userData) => {
            console.log('AFTER SIGNOUT USER DATA: ', userData);
          })
          .catch(() => console.log('Not signed in'));
    }).catch(err => console.log(err));

    dispatch(setUser(null));
    dispatch(setUserToken(null));
  };

  return (
    <header className="Header">
      <div className="Header__LogoContainer">
        <div className="Header__Logo">
          <LogoOlos className="Header__Olos" />
        </div>
        <ul className="Header__Menu">
          {
            Menu.menu.map((item, key) => {
              const modfiedItem = {
                ...item,
                active: currentLink === item.link
              }
              return (
                <li className="Header__MenuItem" key={key}>
                  <OAction opt={modfiedItem} />
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="Header__Container">
        <OUser name={userName} menu={headerMenu || []} onLogoutClick={() => _logOut()} />
      </div>
    </header>
  );
};

export default OHeader;
