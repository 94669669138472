import "./OTableGrid.scss";

type Props = {
  columns: any[];
  data: any[];
  textOverflow?: boolean;
  height?: string;
  scroll?: {
    x?: boolean;
    y?: boolean;
  };
  useGridTemplate?: boolean;
  noDataMessage?: string;
  highlightsLast?: boolean;
  onLineClick?: (line: any) => void;
};

const OTableGrid = (props: Props) => {
  const { columns, data, textOverflow, height, scroll, onLineClick, noDataMessage, highlightsLast, useGridTemplate = true } = props;

  const _sizes = columns.map((column) => column.width || "1fr").join(" ");

  const _scrollX = scroll?.x ? "OTableGrid__Body--ScrollX" : "";
  const _scrollY = scroll?.y ? "OTableGrid__Body--ScrollY" : "";

  const _renderItem = (item: any, _index: number) => {
    return (
      <div
        key={`columns-${_index}`}
        className={`OTableGrid__Columns ${onLineClick ? "OTableGrid__Columns--click" : ""} ${highlightsLast ? 'OTableGrid__Columns--highlightsLast' : ''}`}
        style={useGridTemplate ? { gridTemplateColumns: _sizes } : {}}
        onClick={() => onLineClick && onLineClick(item)}
      >
        {columns.map((column, key) => (
          <div
            key={`content-${key}`}
            className={`OTableGrid__Content ${column.name || ""} ${
              textOverflow ? "OTableGrid__Content--textOverflow" : ""
            } 
            ${column.breakWord ? "OTableGrid__Content--breakWord" : ""}`}
          >
            {column.render ? column.render(item[column.id], item) : item[column.id]?.toString()}
          </div>
        ))}
      </div>
    );
  };

  const _renderRow = (item: any, _index: number) => {
    return (
      <tr
        key={`columns-${_index}`}
        className={`OTableGrid__ ${onLineClick ? "OTableGrid__Columns--click" : ""} ${highlightsLast ? 'OTableGrid__Columns--highlightsLast' : ''}`}
        style={useGridTemplate ? { gridTemplateColumns: _sizes } : {}}
        onClick={() => onLineClick && onLineClick(item)}
      >
        {columns.map((column, key) => (
          <td
            key={`content-${key}`}
            className={`OTableGrid__ ${column.name || ""} ${
              textOverflow ? "OTableGrid__--textOverflow" : ""
            } 
            ${column.breakWord ? "OTableGrid__--breakWord" : ""}`}
          >
            {column.render ? column.render(item[column.id], item) : item[column.id]?.toString()}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className="OTableGrid">
      <table>
        <thead>
          <tr>
            {columns.map((column, key) => (
              <th key={`Header-${key}`} className={`OTableGrid__ ${column.name || ""}`}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((item, index) => _renderRow(item, index))
          ) : (
            <tr>
              <td className="OTableGrid__noDataMessage">{noDataMessage || "Sem dados"}</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* <div
        className={`OTableGrid__Header ${scroll?.y ? "OTableGrid__Header--ScrollY" : ""}`}
        style={useGridTemplate ? { gridTemplateColumns: _sizes } : {}}
      >
        {columns.map((column, key) => (
          <div key={`Header-${key}`} className={`OTableGrid__HeaderColumns ${column.name || ""}`}>
            {column.label}
          </div>
        ))}
      </div>
      <div className={`OTableGrid__Body ${_scrollX} ${_scrollY}`} style={{ height: height || "auto" }}>
        {data.length ? (
          data.map((item, index) => _renderItem(item, index))
        ) : (
          <div className="OTableGrid__noDataMessage">{noDataMessage || "Sem dados"}</div>
        )}
      </div> */}
    </div>
  );
};

export default OTableGrid;
