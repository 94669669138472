export const stringToThousand = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function convertBoolToString(value: boolean | string) {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  return value;
}
export function convertStringToBoolean(value: string | boolean) {
  if (typeof value === 'boolean') {
    return value;
  }
  return value === 'true';
}