import { AniWarning, Lighthouse } from "../@types/OlosTypes";

export const getLighthouseColor = (percentage: number): Lighthouse => {
  const { lightHouseRed, lightHouseYellow, lightHouseGreen } = (window as any).config;

  if (percentage == 0) return "neutral";
  if (percentage >= lightHouseRed) return "red";
  if (percentage >= lightHouseYellow) return "yellow";
  if (percentage >= lightHouseGreen) return "green";

  return "green";
};

export const aniWarnigMessage = (total: number): AniWarning | null => {
  const { aniWarnings } = (window as any).config;
  if (!aniWarnings) return null;

  const _data = aniWarnings as AniWarning[];
  return _data.find((item) => total >= item.total) || null;
};
