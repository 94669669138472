import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import {
  Button,
  OHeader,
  OLoading,
  OPagination,
  OTableGrid,
  OIconButton,
} from "../../components";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import { Pagination } from "../../@types/OlosTypes";
import { getAlerts, getTotalAlerts } from "../../services/Olos/OlosAPI";

import "react-datepicker/dist/react-datepicker.css";
import "./AlertsHistory.scss";
import { checkUserRole } from "../../utils/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const AlertsHistory = () => {
  const {
    itemsPerPage,
  } = (window as any).config;

  const userRoles = useSelector((state: RootState) => state.config.userInfo?.roles);
  const location = useLocation();
  const [dateRange, setDateRange] = useState<any>([new Date().setDate(new Date().getDate()-5), new Date()]);
  const [pageLiveData, setPageLiveData] = useState<any[]>([]);
  const [pageError, setPageError] = useState("");
  const [currentPage, setCurrentPage] = useState<Pagination>({page: 0, offset: 0});
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const navigate = useNavigate();
  
  const [shouldResetPagination, setShouldResetPagination] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const getPagination = async () => {
    let currentPagination = currentPage;

    if (shouldResetPagination) {
      currentPagination = { page: 0, offset: 0 };
      setCurrentPage(currentPagination);
      setShouldResetPagination(false);
    } else {
      setCurrentPage(currentPagination);
    }
    
    const [startDate, endDate] = dateRange;

    const parsedStartDate = parseDate(startDate);
    const parsedEndDate = parseDate(endDate);

    const totalLogs = await getTotalAlerts(parsedStartDate, parsedEndDate);

    if ((totalLogs as AxiosResponse).status === 200) {
      setTotalElements(totalLogs.data.total);
      setResponseError(false);
    } else {
      // setLoadingAlerts(false);
      setPageError("Houve um erro ao recuperar os dados.")
      return setResponseError(true);
    }
  }

  const parseDate = (date: any)=> {
    if (date) {
      if (typeof date === 'string' || typeof date === 'number') {
        date = new Date(date);
      }
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    }

    return '';
  }

  const tableExtractDataColumns = [
    {
      label: "",
      id: 'alert_type',
      render: (alertType:any, data:any)=> {
        return <div className={`AlertIcon ${data.alert_action}`}><AlertIcon /></div>
      },
      // width: '160px'
    },
    {
      label: "Data",
      id: 'alert_time',
      render: (alertTime:any)=> format(new Date(alertTime), 'dd/MM/yyyy - HH:mm')
    },
    {
      label: "Tipo",
      id: 'alert_type',
      // width: '250px'
    },
    {
      label: "Valor",
      id: 'alert_value',
      // width: '100px',
      // render: (error:string) => error === '0' ? 'Não' : 'Sim'
    },
    {
      label: "Ação",
      // width: '150px',
      id: 'alert_action'
    }
  ]

  const getAlertsByDateRange = async () => {
    setLoadingAlerts(true);

    const [startDate, endDate] = dateRange;
    const parsedStartDate = parseDate(startDate);
    const parsedEndDate = parseDate(endDate);

    const alertsData = await getAlerts(parsedStartDate, parsedEndDate, true, 50, currentPage.offset);

    console.log('alertsData', alertsData);

    if ((alertsData as AxiosResponse).status === 200) {
      setPageLiveData(
        alertsData.data.map((item:any) => {
          return { ...item, openDetails: false };
        })
      );
    } else {
      setPageError("");
      setPageLiveData([]);
    }

    setLoadingAlerts(false);
  }
  
  const dateRangeIsValid = ()=> {
    const [startDate, endDate] = dateRange;
    return startDate && endDate;
  }

  useEffect(() => {
    if (dateRangeIsValid()) {
      getPagination();
      getAlertsByDateRange();
    }

  }, [dateRange, currentPage]);

  const [dateStart, dateEnd] = dateRange;

  function renderFiltersSidebar() {
    return (
      <aside className="AlertsHistory__Menu">
        <div className="AlertsHistory__Menu__Content">
          <div className="AlertsHistory__PageTitle">
            <span className="bold">Filtros</span>
          </div>

          <p className="AlertsHistory__Title">Data</p>

          <div className="AlertsHistory__Periodo">
            <div className="DatePicker">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                startDate={dateStart}
                endDate={dateEnd}
                onChange={(dates) => {
                  setDateRange(dates);
                }}
                selectsRange
                locale={ptBR}
                disabled={loadingAlerts}
              />
            </div>

            <Button name="AlertsHistory__Search" label="Pesquisar" disabled={loadingAlerts} onClick={() => {
              getAlertsByDateRange()
            }} />
          </div>
        </div>
      </aside>
    )
  }

  return (
    <section className="AlertsHistory">
      <OHeader currentLink={location.pathname} />
      
      <section className="AlertsHistory__Main">
        { loadingAlerts && <OLoading float /> }

        { renderFiltersSidebar() }

        <main className="AlertsHistory__Content">
          <div className="AlertsHistory__Content__container">
            <div className="AlertsHistory__HeaderContent">
              { checkUserRole("supervisor", userRoles) && 
                <OIconButton label="Gerenciar Alertas" icon="config" onClick={()=> navigate('/alertas/gerenciar')} />
              }
            </div>

            <h1>Histórico de Alertas</h1>

            { !pageLiveData.length &&
              <div className="AlertsHistory__Loading">
                <div className="AlertsHistory__Error">
                  <p className={pageError ? "AlertsHistory__Error" : ""}> {pageError || "Não foram encontrados alertas para o período selecionado."}</p>
                  <br/>
                  <Button size="Small" label="Tentar novamente" onClick={() => {
                      setResponseError(false);
                      getAlertsByDateRange();
                  }} />
                </div>
              </div>
            }
            
            { !!pageLiveData.length && 
              <div className="AlertsHistory__TableData">
                <div className="AlertsHistory__TableContent">
                  <OTableGrid
                    columns={tableExtractDataColumns}
                    data={pageLiveData}
                  />
                </div>

                { totalElements / itemsPerPage > 1 &&
                  <OPagination
                    itemsPerPage={50}
                    items={totalElements}
                    currentPage={currentPage}
                    pageChange={(pagination) => {
                      setCurrentPage(pagination)
                    }}
                  />
                }
              </div>
            }
          </div>
        </main>
      </section>
    </section>
  );
};


        // ==========================================================
        // == OLD ALERT SCREEN
        // ==========================================================

        // {!!pageLiveData.length && (!loadingAlerts || monitoring) ? (
        //   <main className="AlertsHistory__Content">
        //     <div className="AlertsHistory__Content__container">
        //       <h1>Histórico de Alertas</h1>
              
        //       <OIconButton label="Configurações" icon="config" />

        //       <div className="AlertsHistory__TableData">
        //         <div className="AlertsHistory__TableContent">
        //           <OTableGrid
        //             columns={tableExtractDataColumns}
        //             data={pageLiveData}
        //           />
        //           {/* {pageLiveData.map((item, index) => {
        //             return (<p>{item.filename}</p>)
        //           })} */}
        //         </div>

        //         {!!pagination && (
        //           <OPagination
        //             itemsPerPage={50}
        //             items={totalElements}
        //             currentPage={currentPage}
        //             pageChange={(pagination) => {
        //               setCurrentPage(pagination)
        //             }}
        //           />
        //         )}
        //       </div>
        //     </div>
        //   </main>
        // ) : (
        //   <div className="AlertsHistory__Loading">
        //     {loadingAlerts ? (
        //       <OLoading float />
        //       ) : (
        //         <div className="AlertsHistory__Error">
        //           <p className={pageError ? "AlertsHistory__Error" : ""}> {pageError || "Não foram encontrados dados"}</p>
        //           <br/>
        //           <Button name="AlertsHistory__Search" size="Small" label="Tentar novamente" onClick={() => {
        //               setResponseError(false);
        //               getANIList(currentPage);
        //               getFilters();
        //           }} />
        //         </div>
        //     )}
        //   </div>
        // )}